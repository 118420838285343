import React, { Component } from "react";
import Icon from "../../../components/Icon";
import { FetchStatus } from "../../../constants";
import Table from "../../../components/Table";
import SubscriptionStatusIcon from "./SubscriptionStatusIcon";
import { formatter } from "../../../../core/utilities/formatter";
import { FlexBox } from "js/react/components/LayoutGrid";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import UpdateEmailRecipientDialog from "../dialogs/UpdateEmailRecipientDialog";

class TransactionsTable extends Component {
    renderAmount(amount, currency, refunded) {
        let amountString = new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: currency
        }).format(amount);
        if (refunded) {
            return `(${amountString})`;
        }

        return amountString;
    }

    render() {
        const {
            data,
            onUpdateEmail,
            subscription
        } = this.props;
        const noRowsMessage = (
            <div className="no_data_message">
                <Icon iconName="history" />
                <div>No transactions found</div>
            </div>
        );

        const showTransactionsTable = data.length;
        const status = data.length ? { type: FetchStatus.SUCCESS } : { type: FetchStatus.LOADING };

        return showTransactionsTable ? (
            <div className="billing-block" id="transactions">
                {subscription && <FlexBox center horizontalAlign="between">
                    <div
                        className="title"
                        style={{ width: "auto" }}
                    >Payment History</div>
                    <FlexBox
                        center
                        style={{
                            fontSize: 12,
                            color: "#676767"
                        }}
                    >
                        <div>
                            Receipts will be sent to: {subscription.customerEmail }
                        </div>
                        <div
                            onClick={() => ShowDialog(UpdateEmailRecipientDialog, {
                                customerEmail: subscription.customerEmail,
                                onAccept: onUpdateEmail,
                            })}
                            style={{
                                color: "#159BCD",
                                cursor: "pointer",
                                textDecoration: "underline",
                                marginLeft: 4,
                            }}
                        >
                            Edit email
                        </div>
                    </FlexBox>
                </FlexBox>}
                <Table
                    status={status}
                    noRowsMessage={noRowsMessage}
                    columns={[
                        { name: "Status" },
                        { name: "Date" },
                        { name: "Description" },
                        { name: "Payment Method" },
                        { name: "Charge Id" },
                        { name: "Amount" },
                        { name: "Receipt" },
                    ]}
                    rows={data.map(row => {
                        let displayData = [
                            <div className="status">
                                <SubscriptionStatusIcon status={row.status} tooltip={row.status} />
                            </div>,
                            <div className="created">{formatter.formatTimestamp(row.created, false, true)}</div>,
                            <div className="descriptions">
                                {row.description.map((desc, i) => (
                                    <div key={i}>{desc}</div>
                                ))}
                                {row.failure_message && (
                                    <div className="failure">{row.failure_message}</div>
                                )}
                            </div>,
                            <div className="card">{row.card_name}</div>,
                            <div className="charge_id">{row.id}</div>,
                            <div className="amount">{this.renderAmount(row.amount, row.currency, row.refunded)}</div>,
                            <div className="receipt">
                                {row.receipt_url ? (
                                    <a href={row.receipt_url} rel="noreferer, ,noopener" target="_blank" className="receiptIcon">
                                        <Icon iconName="get_app" />
                                    </a>
                                ) : null}
                            </div>
                        ];
                        displayData.isSelected = false;
                        displayData.originalData = row;
                        return displayData;
                    })}
                />
            </div>
        ) : null;
    }
}

export default TransactionsTable;
