import React, { Component, useEffect } from "react";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import styled from "styled-components";
import { app } from "js/namespaces";
import { NodeType } from "common/constants";
import { getStaticUrl } from "js/config";

import { DragPreviewImage, useDrag } from "react-dnd";
import { _ } from "js/vendor";

const Container = styled.div`
  height: 100%;
  padding: 10px 12px 10px;
  background: #f1f1f1;
`;

const DragToAdd = styled.div`
    color: #11a9e2;
    text-transform: uppercase;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 10px;
`;

const Label = styled.label`
    opacity: 0;
    font-weight: 600;
    color: #444;
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    display: block;
    transition: opacity 300ms;
`;

const AnnotationType = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
    padding: 5px 0px;

    &:hover{
        background: #d6eff9;

        ${Label} {
            opacity: 1;
        }
    }
`;

const NodeTypes = styled.div`
    display: flex;
    flex-direction: column;
    gap:5px;
`;

const TypeImage = styled.img`
    width: 100%;
    max-width: 100px;
`;

export const AddType = ({ label, value, image, setIsDragging }) => {
    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: "elements",
        item: { label, value },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId()
        }),
    }));

    useEffect(() => {
        const img = new Image();
        img.src = image;
        img.crossOrigin = "anonymous";
        const ctx = document.createElement("canvas").getContext("2d");

        img.onload = () => {
            const { width, height } = img;
            ctx.canvas.width = width > 100 ? 100 : width;
            ctx.canvas.height = width > 100 ? (100 / (width / height)) : height;

            ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);
            img.src = ctx.canvas.toDataURL();
            preview(img);
        };
    }, []);

    useEffect(() => {
        setIsDragging(isDragging);
    }, [isDragging]);

    return (
        <>
            <DragPreviewImage connect={preview} />
            <AnnotationType ref={drag} >
                <TypeImage src={image}/>
                <Label>{label}</Label>
            </AnnotationType>
        </>
    );
};

const StyledNodeTypes = styled(NodeTypes)`
    overflow: auto;
    height: calc(100% - 75px);
`;

class AddElementPanel extends Component {
    render() {
        const { setIsDragging, currentCanvasController } = this.props;

        let container;
        if (currentCanvasController.canvas.dataModel.get("template_id") === "flow_chart") {
            container = currentCanvasController.canvas.getPrimaryElement();
        } else {
            container = app.currentCanvas.layouter.elements.annotations;
        }

        const allowedNodeTypes = container.getAllowedNodeTypes();

        return (
            <Container>
                <DragToAdd>Drag to add</DragToAdd>

                <StyledNodeTypes>
                    {allowedNodeTypes.contains(NodeType.BOX) && <AddType
                        label="Box"
                        value={NodeType.BOX}
                        image={getStaticUrl("/images/ui/node_types/node_box.svg")}
                        setIsDragging={setIsDragging}
                    />}
                    {allowedNodeTypes.contains(NodeType.CIRCLE) && <AddType
                        label="Circle"
                        value={NodeType.CIRCLE}
                        image={getStaticUrl("/images/ui/node_types/node_circle.svg")}
                        setIsDragging={setIsDragging}
                    />}
                    {allowedNodeTypes.contains(NodeType.DIAMOND) && <AddType
                        label="Diamond"
                        value={NodeType.DIAMOND}
                        image={getStaticUrl("/images/ui/node_types/node_diamond.svg")}
                        setIsDragging={setIsDragging}
                    />}
                    {allowedNodeTypes.contains(NodeType.CAPSULE) && <AddType
                        label="Capsule"
                        value={NodeType.CAPSULE}
                        image={getStaticUrl("/images/ui/node_types/node_capsule.svg")}
                        setIsDragging={setIsDragging}
                    />}
                    {allowedNodeTypes.contains(NodeType.TEXT) && <AddType
                        label="Text"
                        value={NodeType.TEXT}
                        image={getStaticUrl("/images/ui/node_types/node_text.svg")}
                        setIsDragging={setIsDragging}
                    />}
                    {allowedNodeTypes.contains(NodeType.CONTENT_AND_TEXT) && <AddType
                        label="Icon & Text"
                        value={NodeType.CONTENT_AND_TEXT}
                        image={getStaticUrl("/images/ui/node_types/node_content_and_text.svg")}
                        setIsDragging={setIsDragging}
                    />}
                    {allowedNodeTypes.contains(NodeType.BULLET_TEXT) && <AddType
                        label="Bullet"
                        value={NodeType.BULLET_TEXT}
                        image={getStaticUrl("/images/ui/node_types/node_bullet_text.svg")}
                        setIsDragging={setIsDragging}
                    />}
                    {allowedNodeTypes.contains(NodeType.NUMBERED_TEXT) && <AddType
                        label="Numbered"
                        value={NodeType.NUMBERED_TEXT}
                        image={getStaticUrl("/images/ui/node_types/node_numbered_text.svg")}
                        setIsDragging={setIsDragging}
                    />}
                    {allowedNodeTypes.contains(NodeType.CONTENT) && <AddType
                        label="Image/Icon"
                        value={NodeType.CONTENT}
                        image={getStaticUrl("/images/ui/node_types/node_content.svg")}
                        setIsDragging={setIsDragging}
                    />}
                    {allowedNodeTypes.contains(NodeType.LINE) && <AddType
                        label="Line"
                        value={NodeType.LINE}
                        image={getStaticUrl("/images/ui/node_types/node_line.svg")}
                        setIsDragging={setIsDragging}
                    />}
                </StyledNodeTypes>
            </Container>
        );
    }
}

export default PresentationEditorController.withState(AddElementPanel);

