import React, { Component } from "react";
import { MenuItem } from "@material-ui/core";

import { PopupMenu } from "js/react/components/PopupMenu";
import { Gap20, Gap10 } from "js/react/components/Gap";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { SlideStatus, SlideStatusProps } from "common/constants";
import { getStaticUrl } from "js/config";

class SlideStatusMenu extends Component {
    render() {
        const { setSlideStatus, slideStatus = SlideStatus.NONE } = this.props;

        return (
            <PopupMenu childrenAreMenuItems showHoverEffect={false} icon={(
                <img style={{ width: 24, height: 24 }} src={getStaticUrl(`/images/slide-status/${SlideStatusProps[slideStatus].icon}.svg`)} />
            )}>
                {Object.keys(SlideStatusProps).map(status => (
                    <MenuItem
                        key={status}
                        selected={status === slideStatus}
                        onClick={() => setSlideStatus(status)}
                    >
                        <img src={getStaticUrl(`/images/slide-status/menu/${SlideStatusProps[status].icon}.svg`)} />
                        <Gap20 />
                        <label>{SlideStatusProps[status].label}</label>
                        <Gap10 />
                    </MenuItem>
                ))}
            </PopupMenu>
        );
    }
}

export default PresentationEditorController.withState(SlideStatusMenu);
