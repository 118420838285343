import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";

import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { _ } from "js/vendor";
import { getStaticUrl } from "js/config";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";
import { ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import {
    AuthoringBlockType,
    ElementTextBlockPositionType,
    HeaderPositionType, ShowFooterType,
    TextStyleType,
    TrayType
} from "common/constants";
import { deepClone } from "js/core/utilities/extensions";

const Container = styled.div`
  padding: 5px 12px 10px;
  background: #f1f1f1;
  height: 100%;
`;

const Contents = styled.div`
  clear: both;
  padding: 20px 20px 20px;
  position: relative;
  overflow: auto;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Selected = css`
  outline: solid 1px #50bbe6;
`;

const Disabled = css`
  opacity: .4;
  pointer-events: none;
`;

const ThumbnailItem = styled.div`
  position: relative;
  //width: 95px;
  margin-right: 5px;

  ${props => props.selected ? Selected : ""}
  ${props => props.disabled ? Disabled : ""}
  > div {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: solid 1px #888;
  }
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .9px;
  text-transform: uppercase;
  color: #333;
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const Image = styled.img`
  width: 100%;
  display: inline;
  vertical-align: middle;
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

class RenderThumbnail extends Component {
    render() {
        const { icon, enabled, selected, callback } = this.props;
        return (
            <ThumbnailItem disabled={enabled == false} selected={selected == true} className={`${icon}`}
                onClick={() => callback(!selected)}>
                <Image src={`${getStaticUrl(`/images/ui/trays/${icon}.png`)}`} />
                <div></div>
            </ThumbnailItem>
        );
    }
}

class LayoutPanel extends Component {
    renderFooter = (canvas, allowFooter, layout) => {
        if (!allowFooter) return null;

        const theme = canvas.getTheme();

        if (layout.showFooter !== ShowFooterType.OFF && theme.get("showFooterByDefault")) {
            layout.showFooter = ShowFooterType.ON;
        }

        return (<Panel>
            <Label>Footer</Label>
            <Options>
                <RenderThumbnail
                    icon="footer"
                    callback={value => {
                        layout.showFooter = value ? ShowFooterType.ON : ShowFooterType.OFF;
                        canvas.updateCanvasModel(true).then(() => {
                            this.forceUpdate();
                        }).catch(() => {
                            ShowDialogAsync(BadFitDialog, {
                                title: "Sorry, the content on your slide won't fit with footer",
                            });
                        });
                    }}
                    enabled={canvas.layouter.canvasElement.canShowFooter}
                    selected={layout.showFooter === ShowFooterType.ON} />
            </Options>
        </Panel>);
    }

    renderHeaderPanel = (canvas, template, layout) => {
        const renderHeaderOption = (position, enabled) => (<RenderThumbnail
            icon={`header_${position}`}
            enabled={!!layout.trayLayout &&
                    layout.trayLayout !== TrayType.NONE &&
                    (position == HeaderPositionType.LEFT || position == HeaderPositionType.RIGHT) ? false : enabled}
            selected={layout.headerPosition === position}
            callback={value => {
                if (value) {
                    layout.headerPosition = position;
                } else {
                    layout.headerPosition = HeaderPositionType.NONE;
                }
                canvas.markStylesAsDirty();
                canvas.updateCanvasModel(true).then(() => {
                    //this.render();
                    this.forceUpdate();
                }).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: `Sorry, the content on your slide won't fit with header on ${position}`,
                    });
                });
            }}
        />);

        // If header not allowed return nothing
        if (!template.allowHeader) return null;

        const topPosition = template.availableHeaderPositions.contains(HeaderPositionType.TOP);
        const leftPosition = template.availableHeaderPositions.contains(HeaderPositionType.LEFT);

        if (!topPosition && !leftPosition) return null;

        return (<Panel>
            <Label>Header</Label>
            <Options>
                {topPosition && renderHeaderOption(HeaderPositionType.TOP, true)}
                {leftPosition && renderHeaderOption(HeaderPositionType.LEFT, layout.trayLayout === TrayType.NONE || !layout.trayLayout)}
            </Options>
        </Panel>);
    }

    renderCustomItems = canvas => {
        const primaryElementType = canvas.getPrimaryElement().type;
        const name = primaryElementType + "AddContentMenu";

        if (!canvas.editorManager.has(name)) return null;

        let editor = canvas.editorManager.get(name);
        let editorProps = new editor(canvas.getPrimaryElement(), this);

        return (<Panel>
            {editorProps.map(({ title, options }) => (
                <Fragment key={`layout-item-${title}`}>
                    <Label>{title}</Label>
                    <Options>
                        {options.map((props, index) => <RenderThumbnail key={`${title}${index}`} {...props} />)}
                    </Options>
                </Fragment>
            ))}
        </Panel>);
    }

    renderTextLayoutPanel = (canvas, template, layout) => {
        const isEmpty = !template.allowElementTextInline && !template.allowElementTextTray &&
            !template.allowElementAttribution && !template.availableTrayLayouts.contains(TrayType.LEFT_INLINE) &&
            !template.availableTrayLayouts.contains(TrayType.RIGHT_INLINE);

        if (isEmpty) return;

        const renderAllowElementTextInline = () => {
            if (!template.allowElementTextInline) return;

            const testModel = deepClone(canvas.model);
            testModel.layout.elementTextBlockPosition = ElementTextBlockPositionType.TRAY;
            const isFit = canvas.layouter.tryLayout(testModel);

            return (<RenderThumbnail
                icon="bottom_inline_text"
                enabled={isFit}
                selected={layout.elementTextBlockPosition == ElementTextBlockPositionType.INLINE}
                callback={value => {
                    if (value) {
                        layout.elementTextBlockPosition = ElementTextBlockPositionType.INLINE;
                    } else {
                        layout.elementTextBlockPosition = ElementTextBlockPositionType.NONE;
                    }
                    canvas.getCanvasElement().markStylesAsDirty();
                    canvas.updateCanvasModel(false).then(() => {
                        this.forceUpdate();
                    }).catch(() => {
                        ShowDialogAsync(BadFitDialog, {
                            title: "Sorry, the content on your slide won't fit with an extra text tray",
                        });
                    });
                }}
            />);
        };

        const renderAllowElementTextTray = () => {
            if (!template.allowElementTextTray) return;

            const testModel = deepClone(canvas.model);
            testModel.layout.elementTextBlockPosition = ElementTextBlockPositionType.TRAY;
            const isFit = canvas.layouter.tryLayout(testModel);

            return (<RenderThumbnail
                icon="bottom_tray_text"
                enabled={isFit}
                selected={layout.elementTextBlockPosition == ElementTextBlockPositionType.TRAY}
                callback={value => {
                    if (value) {
                        layout.elementTextBlockPosition = ElementTextBlockPositionType.TRAY;
                    } else {
                        layout.elementTextBlockPosition = ElementTextBlockPositionType.NONE;
                    }
                    canvas.getCanvasElement().markStylesAsDirty();
                    canvas.updateCanvasModel(false).then(() => {
                        this.forceUpdate();
                    }).catch(() => {
                        ShowDialogAsync(BadFitDialog, {
                            title: "Sorry, the content on your slide won't fit with an extra text tray",
                        });
                    });
                }}
            />);
        };

        const renderAllowElementAttribution = () => {
            if (!template.allowElementAttribution) return;

            return (<RenderThumbnail
                icon="attribution"
                enabled={true}
                selected={layout.showElementAttribution}
                callback={value => {
                    layout.showElementAttribution = value;
                    canvas.updateCanvasModel(true).then(() => {
                        // For igor to take a look
                        this.forceUpdate();
                    }).catch(() => {
                        ShowDialogAsync(BadFitDialog, {
                            title: "Sorry, the content on your slide won't fit with an attribution text block",
                        });
                    });
                }}
            />);
        };

        let trayModel = canvas.model.elements.tray;
        let blockModel;
        if (trayModel && trayModel.items && trayModel.items.length) {
            blockModel = trayModel.items[0];
        }

        const setInlineText = (position, value) => {
            if (value) {
                layout.trayLayout = position;

                if (canvas.bundleVersion < 10) {
                    if (blockModel) {
                        if (blockModel.blocks == null || blockModel.blocks.length == 0) {
                            blockModel.blocks = [{ type: "body" }];
                        }
                    } else {
                        canvas.model.elements.tray = {
                            items: [{
                                cellColor: "background_accent",
                                blocks: [{
                                    type: "body"
                                }]
                            }]
                        };
                    }
                } else {
                    const text = {
                        blocks: [{
                            html: "",
                            textStyle: TextStyleType.HEADING,
                            type: AuthoringBlockType.TEXT,
                        }]
                    };

                    if (blockModel) {
                        if (blockModel.text == null || blockModel.text.blocks?.length == 0) {
                            blockModel.text = text;
                        }

                        if (blockModel.content_value) {
                            blockModel.textStyle = "white_box";
                        }
                    } else {
                        canvas.model.elements.tray = {
                            items: [{
                                cellColor: "background_accent",
                                text
                            }]
                        };
                    }
                }
            } else {
                if (blockModel.content_value) {
                    blockModel.blocks = null;
                    blockModel.contentBlocks = null;
                    blockModel.text = null;
                } else {
                    canvas.model.layout.trayLayout = TrayType.NONE;
                    canvas.model.elements.tray = null;
                }
            }
            canvas.updateCanvasModel(true).then(() => {
                this.forceUpdate();
            }).catch(() => {
                ShowDialogAsync(BadFitDialog, {
                    title: "Sorry, the content on your slide won't fit with an extra image or text block",
                });
            });
        };

        return (<Panel>
            <Label>Text Boxes</Label>
            <Options>
                {renderAllowElementTextInline()}
                {renderAllowElementTextTray()}
                {renderAllowElementAttribution()}
                {/*{(template.availableTrayLayouts.contains(TrayType.LEFT_INLINE)) && <RenderThumbnail*/}
                {/*    icon="left_inline_text"*/}
                {/*    enabled={layout.headerPosition !== HeaderPositionType.LEFT}*/}
                {/*    selected={layout.trayLayout == TrayType.LEFT_INLINE && blockModel &&*/}
                {/*        (blockModel.blocks?.length || blockModel.contentBlocks?.length || blockModel.text?.blocks?.length)}*/}
                {/*    callback={value => setInlineText(TrayType.LEFT_INLINE, value)}*/}
                {/*/>}*/}
                {/*{(template.availableTrayLayouts.contains(TrayType.RIGHT_INLINE)) && <RenderThumbnail*/}
                {/*    icon="right_inline_text"*/}
                {/*    enabled={layout.headerPosition !== HeaderPositionType.RIGHT}*/}
                {/*    selected={layout.trayLayout == TrayType.RIGHT_INLINE && blockModel &&*/}
                {/*        (blockModel.blocks?.length || blockModel.contentBlocks?.length || blockModel.text?.blocks?.length)}*/}
                {/*    callback={value => setInlineText(TrayType.RIGHT_INLINE, value)}*/}
                {/*/>}*/}
            </Options>
        </Panel>);
    }

    renderTrayLayoutPanel = (canvas, template, layout) => {
        if (template.availableTrayLayouts.length == 0) {
            return null;
        }

        let trayContainer = canvas.layouter.elements.tray;
        let trayModel = canvas.model.elements.tray;
        let blockModel;
        let hasMedia = false;
        let hasText = false;
        if (trayModel && trayModel.items && trayModel.items.length) {
            blockModel = trayModel.items[0];
            hasMedia = blockModel.content_value != null;
            hasText = (blockModel.blocks && blockModel.blocks.length > 0) || (blockModel.contentBlocks && blockModel.contentBlocks.length > 0);
        }

        const renderAvailableTrayLayouts = template.availableTrayLayouts.map((trayLayout, index) => {
            let icon = trayLayout;
            if (template.allowHeader && layout.headerPosition == HeaderPositionType.TOP) {
                icon += "_header";
            } else {
                icon += "_noheader";
            }

            return (<RenderThumbnail
                key={index}
                icon={icon}
                label={trayLayout.split("_")[0].toTitleCase()}
                enabled={layout.headerPosition != HeaderPositionType.LEFT}
                selected={layout.trayLayout == trayLayout}
                callback={value => {
                    if (value) {
                        // set the new tray layout
                        layout.trayLayout = trayLayout;

                        if (canvas.tempTrayModel) {
                            if (!blockModel) {
                                canvas.model.elements.tray = _.cloneDeep(canvas.tempTrayModel);
                                trayModel = canvas.model.elements.tray;
                                blockModel = trayModel.items[0];
                                hasMedia = blockModel.content_value != null;
                            }
                        }

                        if (!trayModel) {
                            canvas.model.elements.tray = {
                                items: [{
                                    cellColor: "background_accent",
                                }]
                            };
                            trayModel = canvas.model.elements.tray;
                        }

                        canvas.updateCanvasModel(true).then(() => {
                            this.render();

                            // if (!hasMedia) {
                            //     ShowDialog(AddAssetsContainer, {
                            //         assetType: AssetType.IMAGE,
                            //         workspaceId: ds.selection.presentation.getWorkspaceId(),
                            //         callback: model => {
                            //             if (!blockModel) {
                            //                 canvas.model.elements.tray.items = [{}];
                            //             }
                            //
                            //             mergeMediaElementModelDefaults(
                            //                 canvas.model.elements.tray.items[0],
                            //                 model,
                            //             );
                            //
                            //             canvas.updateCanvasModel(false).then(() => {
                            //                 this.forceUpdate();
                            //             }).catch(() => {
                            //                 ShowDialogAsync(BadFitDialog, {
                            //                     title: "Sorry, the content on your slide won't fit with an extra image or text block",
                            //                 });
                            //             });
                            //         },
                            //     });
                            // }
                        }).catch(() => {
                            ShowDialogAsync(BadFitDialog, {
                                title: "Sorry, the content on your slide won't fit with an extra image or text block",
                            });
                        });
                    } else {
                        // store current tray model in temp so we can restore if the user adds the tray back
                        canvas.tempTrayModel = _.cloneDeep(canvas.model.elements.tray);

                        if (hasText) {
                            blockModel.content_value = null;
                            blockModel.content_type = null;
                        } else {
                            // remove tray model
                            canvas.model.elements.tray = null;
                            canvas.model.layout.trayLayout = TrayType.NONE;
                        }

                        canvas.updateCanvasModel(true).then(() => {
                            this.forceUpdate();
                        }).catch(() => {
                            ShowDialogAsync(BadFitDialog, {
                                title: "Sorry, the content on your slide won't fit with an extra image or text block",
                            });
                        });
                    }
                }}
            />);
        });

        return (<Panel>
            <Label>Trays</Label>
            <Options>
                {renderAvailableTrayLayouts}
            </Options>
        </Panel>);
    };

    render() {
        const { currentCanvasController } = this.props;
        const canvas = currentCanvasController.canvas;
        const template = canvas.layouter.template;
        const layout = canvas.model.layout;

        return (
            <Container onScroll={event => event.stopPropagation()}>
                <Contents>
                    {this.renderCustomItems(canvas)}
                    {this.renderHeaderPanel(canvas, template, layout)}
                    {this.renderFooter(canvas, template.allowFooter, layout)}
                    {this.renderTextLayoutPanel(canvas, template, layout)}
                    {this.renderTrayLayoutPanel(canvas, template, layout)}
                </Contents>
            </Container>
        );
    }
}

export default PresentationEditorController.withState(LayoutPanel);

