import React, { Component } from "react";
import styled from "styled-components";
import { Avatar, Icon } from "@material-ui/core";
import getLogger, { LogGroup } from "js/core/logger";
import { FlexSpacer } from "js/react/components/Gap";
import { app } from "js/namespaces";
import { ShowDialogAsync, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { ShareDialog } from "js/react/views/PresentationSettings/dialogs/ShareDialog";
import { ds } from "js/core/models/dataService";
import { AssignCollaboratorOrTeammateDropdown } from "js/editor/components/AssignCollaboratorOrTeammateDropdown";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import { trackActivity } from "js/core/utilities/utilities";
import NotificationsService from "js/core/services/notifications";
import InviteEditCollaboratorDialog from "js/react/components/Dialogs/InviteEditCollaboratorDialog";
import { HelpButton } from "js/editor/components/helpButton";
import { Divider } from "js/react/components/UiComponents";
import CollaborationView from "./CollaborationView";
import { IconButton, SideBar } from "js/editor/PresentationEditor/Components/SharedComponents";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import SlideStatusMenu from "js/editor/PresentationEditor/Components/SlideStatusMenu";
import { SlideStatus, SlideStatusProps } from "common/constants";
import { getStaticUrl } from "js/config";

const logger = getLogger(LogGroup.COLLABORATION);

const CommentsCountLabelContainer = styled.div`
    left: 50%;
    top: calc(50% - 10px);
    transform: translate(-50%,-50%);

    position: absolute;

    span {
        font-size: 10px;
        color: #000000;
    }
`;

const UnreadCommentsRedDot = styled.span`
    width: 6px;
    height: 6px;
    background-color: #ff0000;
    border-radius: 50%;
    border: 1px solid #333333;
    position: absolute;
    left: 11px;
    top: 11px;
`;

export class CollaborationBar extends Component {
    state = {
        slideStatus: SlideStatus.NONE,
        assignedUser: null,
        anchorEl: null,
        collaborators: [],
        teammates: [],
        comments: [],
        isShowingDialog: false
    };

    constructor() {
        super();

        this.user = app.user.getAuthUser();
    }

    componentDidMount() {
        const { collaboratorsAndTeammates$, assignedToSlideUser$, slideComments$, slideStatus$ } = ds.getObservables();
        this.collaboratorsAndTeammatesSubscription = collaboratorsAndTeammates$.subscribe(this.handleCollaboratorsAndTeammatesUpdate);
        this.assignedToSlideUserSubscription = assignedToSlideUser$.subscribe(this.handleAssignedUserUpdate);
        this.commentsSubscription = slideComments$.subscribe(this.handleCommentsUpdate);
        this.slideStatusSubscription = slideStatus$.subscribe(this.handleSlideStatusUpdate);
    }

    componentWillUnmount() {
        if (this.collaboratorsAndTeammatesSubscription) {
            this.collaboratorsAndTeammatesSubscription.unsubscribe();
        }

        if (this.assignedToSlideUserSubscription) {
            this.assignedToSlideUserSubscription.unsubscribe();
        }

        if (this.commentsSubscription) {
            this.commentsSubscription.unsubscribe();
        }

        if (this.slideStatusSubscription) {
            this.slideStatusSubscription.unsubscribe();
        }
    }

    showDialog(...args) {
        this.setState({ isShowingDialog: true });

        return ShowDialogAsync(...args)
            .then(dialogResult => {
                this.setState({ isShowingDialog: false });
                return dialogResult;
            });
    }

    closeCollaboratorDropdown = () => {
        this.setState({ anchorEl: null });
    }

    handleCollaboratorsAndTeammatesUpdate = ({ collaborators, teammates }) => {
        this.setState({ collaborators, teammates });
    }

    handleAssignedUserUpdate = assignedUser => {
        this.setState({ assignedUser });
    }

    handleCommentsUpdate = comments => {
        this.setState({ comments });
    }

    handleShowComments = () => {
        ds.selection.element = null;
        PresentationEditorController.toggleComments();
    }

    handleAddCollaborator = async (email = "", onInviteSuccess = null) => {
        ds.selection.element = null;
        if (await this.showDialog(ShareDialog, {
            emails: [email],
            presentation: ds.selection.presentation,
            selectedPanel: "collaborate",
            location: "assign"
        })) {
            onInviteSuccess && onInviteSuccess();
        }
    }

    assignUserToSlide = user => {
        const slide = ds.selection.slide;

        const assignUser = () => {
            if (user.isPending && slide.get("assignedPendingUser") !== user.email) {
                slide.update({ assignedUser: null, assignedPendingUser: user.email, pendingUserAssignedBy: app.user.id });
                if (slide.isLibrarySlide()) {
                    app.mainView.editorView.handleSaveSharedSlide();
                }
            } else if (slide.get("assignedUser") !== user.uid) {
                slide.update({ assignedUser: user.uid, assignedPendingUser: null, pendingUserAssignedBy: null });
                if (slide.isLibrarySlide()) {
                    app.mainView.editorView.handleSaveSharedSlide();
                }
                // Notifying the assignee
                NotificationsService.notifyOnUserAssignedToSlide(user.uid, ds.selection.presentation.id, slide.id)
                    .catch(err => logger.error(err, "[CollaborationBar] NotificationsService.notifyOnUserAssignedToSlide() failed", { slideId: slide?.id }));
            }
            const props = {
                slide_id: slide.id,
                email: user.email,
                user_id: user.uid || null
            };
            trackActivity("Collab", "Assign", null, null, props, { audit: true });
        };

        if (slide.isLibrarySlide()) {
            ShowConfirmationDialog({
                title: "Assigning user will save this Team Slide, are you sure?",
                message: "Changes will be applied to all presentations that use this slide.",
                cancelButtonLabel: "Cancel",
                acceptCallback: assignUser,
                cancelCallback: () => { }
            });
        } else {
            assignUser();
        }
    }

    handleAssignCollaborator = user => {
        if (user.permissionType === "view") {
            this.showDialog(InviteEditCollaboratorDialog, {
                title: `Would you like to give ${user.displayName || user.email} edit permission?`,
                message: `This collaborator currently has view only access. Only collaborators with edit permission can be assigned a slide.`,
                user,
                old_value: user.permissionType
            }).then(wasInvited => {
                if (wasInvited) {
                    this.assignUserToSlide(user);
                    this.closeCollaboratorDropdown();
                }
            });
        } else {
            this.assignUserToSlide(user);
            this.closeCollaboratorDropdown();
        }
    }

    handleAssignTeammate = user => {
        this.showDialog(InviteEditCollaboratorDialog, {
            title: `Would you like to invite ${user.displayName || user.email} to collaborate on and edit this presentation?`,
            message: `Only collaborators with edit permission can be assigned a slide.`,
            user
        }).then(wasInvited => {
            if (wasInvited) {
                this.assignUserToSlide(user);
                this.closeCollaboratorDropdown();
            }
        });
    }

    handleAssignSelect = item => {
        let {
            collaborators,
            teammates,
        } = this.state;
        let user = collaborators.find(x => x.email === item.email);
        if (user) {
            this.handleAssignCollaborator(user);
            return;
        }
        user = teammates.find(x => x.uid === item.id);
        if (!user) {
            user = {
                uid: item.uid || item.id,
                email: item.email,
                isPending: true,
            };
        }
        this.handleAssignTeammate(user);
    }

    handleUnassignUser = () => {
        ds.selection.slide.update({ assignedUser: null, assignedPendingUser: null });
    }

    handleClickAssignButton = event => {
        const { currentSlide } = this.props;
        ds.selection.element = null;
        if (currentSlide.isLibrarySlide()) {
            ShowErrorDialog({ title: "Unable to assign user to this slide", message: "This is shared team slide and can not be assigned to a user" });
            return;
        }
        this.setState({ anchorEl: event.currentTarget });
    }

    handleSlideStatusUpdate = slideStatus => {
        this.setState({ slideStatus: slideStatus ?? SlideStatus.NONE });
    }

    setSlideStatus = async status => {
        const { presentation, currentSlide } = this.props;
        if (currentSlide.get("status") === status) {
            return;
        }

        // Checking if the slide is a shared team slide
        if (currentSlide.isLibrarySlide()) {
            ShowErrorDialog({ title: "Unable to update slide status", message: "This is a shared team slide and cannot be updated" });
            return;
        }

        // Updating the slide status
        ds.selection.element = null;
        currentSlide.update({ status });

        // Notifying the status change to owners and collaborators
        NotificationsService.notifyOnSlideStatusChanged(presentation.id, currentSlide.id, status)
            .catch(err => logger.error(err, "[CollaborationBar] NotificationsService.notifyOnSlideStatusChanged() failed", { slideId: currentSlide?.id }));

        // Tracking the activity
        const props = {
            presentation_id: presentation.id,
            slide_id: currentSlide.id,
            status
        };
        trackActivity("Collab", "SlideStatus", null, null, props, { audit: true });
    }

    render() {
        const {
            presentation,
            showComments,
            showSlideStatus,
            showAssignUser,
            showCollaboration,
        } = this.props;
        const {
            comments,
            anchorEl,
            assignedUser,
            slideStatus,
            isShowingDialog,
        } = this.state;

        const hasUnreadComments = comments.some(comment => comment.authorUid !== this.user.uid && (!comment.readBy || !comment.readBy[this.user.uid]));
        const isTemplate = presentation.get("isTemplate");
        const showDividers = showSlideStatus && showAssignUser;

        return (
            <SideBar gap={20} top={20} style={{ width: 60 }}>
                {
                    showComments &&
                    <>
                        <IconButton id="show-comments" onClick={() => PresentationEditorController.toggleComments()}>
                            <Icon>mode_comments</Icon>
                            {comments.length > 0 &&
                                <CommentsCountLabelContainer>
                                    <span>
                                        {comments.length}
                                    </span>
                                </CommentsCountLabelContainer>
                            }
                            {hasUnreadComments && <UnreadCommentsRedDot />}
                            <label>Comments</label>
                        </IconButton>
                        {showDividers && <Divider color={"rgb(85, 85, 85)"} margin={0} />}
                    </>
                }
                {
                    showAssignUser &&
                    <>
                        <IconButton id="assign-to-user" onClick={this.handleClickAssignButton}>
                            {assignedUser
                                ? <Avatar title={assignedUser.displayName || assignedUser.email} src={assignedUser.photoURL} variant="circle" style={{ height: "30px", width: "30px" }} />
                                : <img src={getStaticUrl("/images/slide-status/assigned-no-avatar.svg")} />
                            }
                            <label>Owner</label>
                        </IconButton>
                        {
                            anchorEl &&
                            <AssignCollaboratorOrTeammateDropdown
                                sourceName="AssignUser"
                                anchorEl={anchorEl}
                                presentation={presentation}
                                showAssignUserInput={true}
                                onSelect={this.handleAssignSelect}
                                onAddCollaboratorClick={this.handleAddCollaborator}
                                assignedUser={assignedUser}
                                onUnassignClick={this.handleUnassignUser}
                                onClose={() => {
                                    if (!isShowingDialog) this.closeCollaboratorDropdown();
                                }}
                            />
                        }
                    </>
                }
                {
                    showSlideStatus &&
                    <IconButton id="update-slide-status" customIcon>
                        <SlideStatusMenu slideStatus={slideStatus} setSlideStatus={this.setSlideStatus} />
                        <label>{SlideStatusProps[slideStatus].label.toUpperCase()}</label>
                    </IconButton>
                }
                {
                    !isTemplate &&
                    showCollaboration &&
                    <>
                        {showDividers && <Divider color={"rgb(85, 85, 85)"} margin={0} />}
                        <CollaborationView />
                        <IconButton className="add-collaborators" onClick={this.handleAddCollaborator}>
                            <Icon>person_add_alt_1</Icon>
                            <label>Invite</label>
                        </IconButton>
                    </>
                }
                <FlexSpacer />
                <HelpButton source="editor" />
            </SideBar>
        );
    }
}

export default PresentationEditorController.withState(CollaborationBar);
