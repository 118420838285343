import React, { Component } from "react";
import styled from "styled-components";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { _ } from "js/vendor";
import { getStaticUrl } from "js/config";
import { trackActivity } from "js/core/utilities/utilities";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { Gap30 } from "js/react/components/Gap";
import { BlueButton } from "js/react/components/UiComponents";
import { switchSlideTemplate } from "js/core/services/sharedModelManager";

export const LAYOUT_PANEL_WIDTH = 200;

const Container = styled.div`
  color: black;
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding-top: 20px;
  width: ${LAYOUT_PANEL_WIDTH}px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 65px;
`;

const VariationsThumbnail = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 130px;
    border: solid 1px #ccc;
  }

  label {
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 600;
  }
`;

const Warning = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 10px;
`;

class VariationsPanel extends Component {
    state = { variations: [] }

    get canvas() {
        return this.props.currentCanvasController.canvas;
    }

    componentDidMount() {
        this.loadVariations();
        if (this.props.currentSlide) {
            this.props.currentSlide.presentation.on("slideTemplateChanged", this.loadVariations);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentSlide != prevProps.currentSlide) {
            this.loadVariations();
        }
    }

    componentWillUnmount() {
        if (this.props.currentSlide) {
            this.props.currentSlide.presentation.off("slideTemplateChanged", this.loadVariations);
        }
    }

    loadVariations = () => {
        const template = this.canvas.slideTemplate;
        if (template.variations) {
            this.setState({
                variations: this.canvas.variations[template.variations]
            });
        } else {
            this.setState({
                variations: []
            });
        }
    }

    handleSelectVariation = async variation => {
        const currentTemplate = this.props.currentSlide.get("template_id");
        const targetTemplate = variation.template;

        try {
            if (!variation.model) {
                await switchSlideTemplate(this.canvas, variation.template, variation.templateProps);
            } else {
                let model = variation.model;

                if (model.primary) {
                    const element = this.canvas.getPrimaryElement();
                    if (model.replaceModel) {
                        // we have to keep the reference to element.model
                        Object.keys(element.model).forEach(key => delete element.model[key]);
                        Object.assign(element.model, model.primary);
                    } else {
                        // trim any arrays in the model that are being modified so we remove orphaned children
                        let clearArrayProps = (targetModel, sourceModel, path) => {
                            let sourceNode = path ? _.get(sourceModel, path) : sourceModel;
                            if (sourceNode && typeof sourceNode === "object") {
                                for (let key of Object.keys(sourceNode)) {
                                    if (sourceNode[key] instanceof Array) {
                                        let targetNode = path ? _.get(targetModel, path) : targetModel;
                                        targetNode[key] = _.take(targetNode[key], sourceNode[key].length);
                                    } else {
                                        clearArrayProps(targetModel, sourceModel, path ? path + "." + key : key);
                                    }
                                }
                            }
                        };
                        clearArrayProps(element.model, model.primary, null);

                        _.merge(element.model, model.primary);
                    }
                }

                let transition = variation.transition ?? true;
                if (targetTemplate && targetTemplate !== currentTemplate) {
                    transition = false;
                    this.canvas.model.template_id = targetTemplate;
                }

                if (variation.callbackMethod) {
                    this.canvas.getPrimaryElement()[variation.callbackMethod](variation.callbackArguments, variation);
                }

                this.canvas.markStylesAsDirty();

                await this.canvas.updateCanvasModel(transition);

                if (model.postCanvasUpdateCallback) {
                    await model.postCanvasUpdateCallback();
                }
            }

            trackActivity("Slide", "SwitchVariation", null, null, { template_id: variation.template }, { audit: false });
        } catch (err) {
            ShowWarningDialog({
                title: "Sorry, we were unable to make this change",
                message: err.message,
            });
        }
    }

    render() {
        const { variations } = this.state;

        const canvasController = PresentationEditorController.getCurrentCanvasController();

        if (variations.length) {
            return (
                <Container>
                    {variations.map(variation => this.renderThumbnail(variation))}
                </Container>
            );
        } else {
            return (
                <Container>
                    <Warning>No predefined variations are available yet for this template</Warning>
                    <Gap30 />
                    <BlueButton onClick={() => canvasController.switchTemplate()}>Switch Template...</BlueButton>
                </Container>
            );
        }
    }

    renderThumbnail = variation => {
        let thumbnailUrl;
        if (variation.icon) {
            thumbnailUrl = getStaticUrl(`/images/template-icons/${variation.icon}.svg`);
        } else {
            let slideTemplate = this.canvas.slideTemplates[variation.template];
            if (slideTemplate) {
                thumbnailUrl = getStaticUrl(`/images/template-icons/${slideTemplate.icon}.svg`);
            }
        }

        return (
            <VariationsThumbnail onClick={() => this.handleSelectVariation(variation)}>
                <img src={thumbnailUrl} />
                <label>{variation.label}</label>
            </VariationsThumbnail>
        );
    }
}

export default PresentationEditorController.withState(VariationsPanel);
