import firebase from "js/firebase";
import React, { Component } from "reactn";
import {
    BeautifulDialog,
    DialogContent,
    ShowErrorDialog,
    ShowMessageDialog
} from "js/react/components/Dialogs/BaseDialog";
import {
    Button,
    DialogActions,
    DialogTitle,
    TextField
} from "@material-ui/core";
import getLogger, { LogGroup } from "js/core/logger";
import { Gap10 } from "js/react/components/Gap";
import { trackActivity } from "js/core/utilities/utilities";

const logger = getLogger(LogGroup.USER);

export class ChangePasswordDialog extends Component {
    state = {
        current_password: "",
        new_password: "",
        confirm_password: "",
        confirmError: false
    };

    changePassword = () => {
        let { current_password, new_password, confirm_password } = this.state;

        let user = firebase.auth().currentUser;

        if (new_password !== confirm_password) {
            // $confirmPW.next('.input-error').text("Passwords do not match.");
            // this.$submitButton.prop('disabled', false);
            this.setState({ confirmError: "Please confirm your password" });
        } else {
            user.reauthenticateAndRetrieveDataWithCredential(
                firebase.auth.EmailAuthProvider.credential(
                    user.email,
                    current_password
                )
            )
                .then(() => {
                    user.updatePassword(new_password)
                        .then(() => {
                            user.reauthenticateAndRetrieveDataWithCredential(
                                firebase.auth.EmailAuthProvider.credential(
                                    user.email,
                                    new_password
                                )
                            )
                                .then(() => {
                                    this.props.closeDialog();
                                    trackActivity("Auth", "ChangedPassword", null, null, { workspace_id: "all" }, { audit: true });
                                    ShowMessageDialog({
                                        title: "Password changed",
                                        message:
                                            "Your password has been updated."
                                    });
                                })
                                .catch(err => logger.error(err, "user.reauthenticateAndRetrieveDataWithCredential() failed"));
                        })
                        .catch(err => logger.error(err, "user.updatePassword() failed"));
                })
                .catch(err => {
                    ShowErrorDialog({
                        error: "Failed to change password",
                        message: err.message
                    });
                    if (err.code !== "auth/wrong-password") {
                        logger.error(err, "user.reauthenticateAndRetrieveDataWithCredential() failed");
                    }
                });
        }
    };

    render() {
        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>Change your password.</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Current Password"
                        placeholder="Enter your current password"
                        type="password"
                        onChange={event =>
                            this.setState({
                                current_password: event.target.value
                            })
                        }
                        fullWidth={true}
                    />
                    <Gap10 />
                    <TextField
                        label="New Password"
                        placeholder="Enter your new password"
                        type="password"
                        onChange={event =>
                            this.setState({ new_password: event.target.value })
                        }
                        fullWidth={true}
                    />
                    <Gap10 />
                    <TextField
                        error={this.state.confirmError !== false}
                        label="Confirm Password"
                        placeholder="Confirm your password"
                        type="password"
                        onChange={event =>
                            this.setState({
                                confirm_password: event.target.value
                            })
                        }
                        fullWidth={true}
                        helperText={
                            this.state.confirmError
                                ? "Passwords don't match"
                                : null
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog}>Cancel</Button>
                    <Button onClick={this.changePassword} color="primary">
                        Change Password
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
