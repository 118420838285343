import Cookies from "js-cookie";

import firebase from "js/firebase";
import { openWindowAndWaitForItToBeClosed } from "js/core/utilities/selfClosingWindow";

export class SalesforceAuthError extends Error { }
export class SalesforceAuthFlowWasInterruptedError extends SalesforceAuthError { }

interface SalesforceAuthTokens {
    accessToken: string
}

/**
 * Grants our app offline access for the given scope, executes the redirect ouath flow in a separate window
 * and saves the tokens
 */
export async function grantOfflineAccess(orgId, clientId, domainName, prn): Promise<SalesforceAuthTokens> {
    const userIdToken = await firebase.auth().currentUser.getIdToken();
    Cookies.set("user_id_token", userIdToken, { sameSite: "strict", expires: new Date(Date.now() + 60 * 1000) });

    const url = `${window.location.origin}/salesforce/auth` +
        `?continueUrl=${encodeURIComponent("/close")}` +
        `&clientId=${encodeURIComponent(clientId)}` +
        `&domainName=${encodeURIComponent(domainName)}` +
        `&prn=${encodeURIComponent(prn)}` +
        `&orgId=${encodeURIComponent(orgId)}` +
        "&requestOfflineAccess=true";

    const lastSearchParams = await openWindowAndWaitForItToBeClosed(url);

    const error = lastSearchParams.get("salesforce_auth_error");
    if (error) {
        throw new SalesforceAuthError(error);
    }

    const accessToken = Cookies.get("salesforce_access_token");
    Cookies.remove("salesforce_access_token");

    if (!accessToken && !error) {
        throw new SalesforceAuthFlowWasInterruptedError();
    }

    return { accessToken };
}
