import React, { Component } from "react";

import styled from "styled-components";
import { FlexSpacer } from "js/react/components/Gap";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { themeColors } from "js/react/sharedStyles";
import UndoRedo from "js/react/components/UndoRedo";

const MenuBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #222;
  width: 100%;
  height: 50px;
  padding: 0px;
  color: white;
  flex-shrink: 0;
  gap: 20px;

  .MuiIcon-root {
    color: white;
  }
`;
const NavMenuContainer = styled.div`
  width: 70px;
  height: 100%;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .material-icons {
    color: ${themeColors.ui_blue};
  }
`;

class PresentationMenuBar extends Component {
    render() {
        return (
            <MenuBarContainer>
                <NavMenuContainer />
                <UndoRedo />
                <FlexSpacer />
            </MenuBarContainer>
        );
    }
}

export default PresentationEditorController.withState(PresentationMenuBar);
