import React, { Component } from "react";
import styled from "styled-components";

import { Checkbox } from "@material-ui/core";

import { ds } from "js/core/models/dataService";
import * as geom from "js/core/utilities/geom";

import { AnimationArrangementControl } from "../AnimationArrangementControl";
import { TimelineAnimation } from "./TimelineAnimation";
import { FlexSpacer, Gap10 } from "js/react/components/Gap";

const Container = styled.div`
    width: 100%;
    margin-top: 10px;

    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const TimelineContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const TimelineHeader = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: #222;
    display: flex;
    align-items: center;
    width: 100%;
`;

const TimelineAnimationsContainer = styled.div.attrs(({ disabled, height }) => ({
    style: {
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.8 : 1,
        height
    }
}))`
    position: relative;
    width: 100%;
    margin-top: -2px;
    overflow-x: hidden;
    overflow-y: auto;

    padding: 0px;

    display: grid;
    gap: 5px;
    grid-template-columns: 100%;
`;

const TimelineControlsContainer = styled.div.attrs(({ disabled }) => ({
    style: {
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.6 : 1,
    }
}))`
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    >div {
        margin-left: 5px;

        font-weight: 600;
        font-size: 10px;
        color: #666;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
`;

const DraggedAnimationContainer = styled.div.attrs(({ left, top }) => ({
    style: {
        left: `${left}px`,
        top: `${top}px`
    }
}))`
    position: absolute;
    z-index: 9999;
`;

const FakeTopPaddingBar = styled.div`
    position: absolute;
    left: 0;
    top: 18px;
    width: 210px;
    height: 11px;
    background-color: #f1f1f1;
    z-index: 9;
`;

class Timeline extends Component {
    constructor() {
        super();

        this.state = {
            hideDisabledAnimations: false,
            draggedAnimationId: null,
            dragDropTargetAnimationId: null,
            dragHandlePosition: null,
            selectedAnimationIds: [],
            isHoveringOverTimeline: false
        };

        this.animationsContainerRef = React.createRef();
        this.animationsContainerBounds = null;

        this.animationsRefs = {};
        this.animationsBounds = {};
    }

    componentDidMount() {
        ds.selection.on("change:element change:authoringElements", this.onSelectedElementsChange);
    }

    componentWillUnmount() {
        const { canvas } = this.props;
        canvas.dimHilitedElements();

        ds.selection.off("change:element change:authoringElements", this.onSelectedElementsChange);
    }

    onSelectedElementsChange = ({ element, authoringElements }) => {
        const { animations } = this.props;

        const elementsAreRelated = (elementA, elementB) =>
            elementA && elementB && (
                elementA === elementB ||
                elementA.isChildOf(elementB) ||
                elementB.isChildOf(elementA)
            );

        if (authoringElements.length > 0) {
            const selectedAnimationIds = animations
                .filter(animation => authoringElements.some(authoringElement => elementsAreRelated(animation.element, authoringElement)))
                .map(({ id }) => id);

            this.setState({ selectedAnimationIds });
        } else if (element) {
            const selectedAnimationIds = animations
                .filter(animation => elementsAreRelated(animation.element, element))
                .map(({ id }) => id);

            this.setState({ selectedAnimationIds });
        }
    }

    handleDragStart = (event, animationId) => {
        const { animations } = this.props;

        this.animationsContainerBounds = geom.Rect.FromBoundingClientRect(this.animationsContainerRef.current.getBoundingClientRect());
        this.animationsBounds = animations
            .filter(({ id }) => !!this.animationsRefs[id].current)
            .reduce((animationsBounds, animation) => ({
                ...animationsBounds,
                [animation.id]: geom.Rect.FromBoundingClientRect(this.animationsRefs[animation.id].current.getBoundingClientRect())
            }), {});

        const draggedAnimationBounds = this.animationsBounds[animationId];
        // To avoid excessive gymnastics will just adjust the container bounds by the animation offset
        this.animationsContainerBounds.left += event.pageX - draggedAnimationBounds.left;
        this.animationsContainerBounds.top += event.pageY - draggedAnimationBounds.top;

        this.setState({
            draggedAnimationId: animationId,
            dragDropTargetAnimationId: animationId,
            dragHandlePosition: {
                x: event.pageX - this.animationsContainerBounds.left,
                y: event.pageY - this.animationsContainerBounds.top,
            }
        }, () =>
            window.addEventListener("mousemove", this.handleDrag)
        );
    }

    handleDrag = event => {
        let highestMatchingAnimationId;
        for (const [animationId, animationBounds] of Object.entries(this.animationsBounds)) {
            if (event.pageY < animationBounds.centerV) {
                if (!highestMatchingAnimationId) {
                    highestMatchingAnimationId = animationId;
                } else {
                    const highestMatchingAnimationBounds = this.animationsBounds[highestMatchingAnimationId];
                    if (animationBounds.top < highestMatchingAnimationBounds.top) {
                        highestMatchingAnimationId = animationId;
                    }
                }
            }
        }

        this.setState({
            dragDropTargetAnimationId: highestMatchingAnimationId,
            dragHandlePosition: {
                x: event.pageX - this.animationsContainerBounds.left,
                y: event.pageY - this.animationsContainerBounds.top,
            }
        });
    }

    handleDragEnd = async () => {
        const {
            animations,
            canvas
        } = this.props;
        const {
            dragDropTargetAnimationId,
            draggedAnimationId,
            isHoveringOverTimeline
        } = this.state;

        window.removeEventListener("mousemove", this.handleDrag);

        if (dragDropTargetAnimationId !== draggedAnimationId) {
            const animationIds = animations.map(({ id }) => id);

            const draggedAnimationIndex = animationIds.indexOf(draggedAnimationId);
            const dragDropTargetAnimationIndex = animationIds.indexOf(dragDropTargetAnimationId);

            if (!dragDropTargetAnimationId) {
                // Pushing to the end
                animationIds.splice(draggedAnimationIndex, 1);
                animationIds.push(draggedAnimationId);
            } else {
                if (dragDropTargetAnimationIndex < draggedAnimationIndex) {
                    animationIds.splice(dragDropTargetAnimationIndex, 0, draggedAnimationId);
                    animationIds.splice(draggedAnimationIndex + 1, 1);
                } else if (dragDropTargetAnimationIndex + 1 === draggedAnimationIndex) {
                    animationIds[dragDropTargetAnimationIndex] = draggedAnimationId;
                    animationIds[draggedAnimationIndex] = dragDropTargetAnimationId;
                } else {
                    animationIds.splice(dragDropTargetAnimationIndex, 0, draggedAnimationId);
                    animationIds.splice(draggedAnimationIndex, 1);
                }
            }

            // Saving order
            canvas.updateGeneralAnimationsSettings({ animationsOrder: animationIds });
            await canvas.saveCanvasModel();
        }

        if (!isHoveringOverTimeline) {
            canvas.dimHilitedElements();
        }

        this.setState({ draggedAnimationId: null, dragDropTargetAnimationId: null, dragHandlePosition: null });
    }

    handleCreateCustomAnimation = async (beforeAnimationId, customAnimation) => {
        const {
            animations,
            canvas,
            generalAnimationsSettings: {
                customAnimationIds
            }
        } = this.props;

        // Saving custom animation
        canvas.updateAnimation(customAnimation.id, customAnimation);

        // Generating updated animations order
        const animationIds = animations.map(({ id }) => id);
        animationIds.splice(animationIds.indexOf(beforeAnimationId), 0, customAnimation.id);
        // Saving updated order and adding custom animation to custom animations list
        canvas.updateGeneralAnimationsSettings({
            animationsOrder: animationIds,
            customAnimationIds: [...customAnimationIds, customAnimation.id]
        });

        // Saving model changes
        await canvas.saveCanvasModel();
    }

    handleRemoveCustomAnimation = async animationId => {
        const {
            canvas,
            generalAnimationsSettings: {
                customAnimationIds
            }
        } = this.props;

        canvas.updateGeneralAnimationsSettings({
            customAnimationIds: [...customAnimationIds.filter(id => id !== animationId)]
        });
        await canvas.saveCanvasModel();
    }

    handleMouseMoveAnimation = animation => {
        const { canvas } = this.props;
        const { selectedAnimationIds } = this.state;

        if (selectedAnimationIds.includes(animation.id)) {
            return;
        }

        if (animation.element) {
            canvas.hiliteElements([animation.element]);
        } else {
            // Remove hilights for custom animations (wait/delay)
            canvas.dimHilitedElements();
        }
        this.setState({ selectedAnimationIds: [animation.id] });
    }

    handleMouseLeaveAnimation = animation => {
        this.setState(({ selectedAnimationIds }) => {
            if (selectedAnimationIds.includes(animation.id)) {
                return { selectedAnimationIds: [] };
            }
            return {};
        });
    }

    handleMouseEnterTimeline = () => {
        const { canvas, animations } = this.props;
        const { selectedAnimationIds, draggedAnimationId } = this.state;

        this.setState({ isHoveringOverTimeline: true });

        if (selectedAnimationIds.length > 0 && !draggedAnimationId) {
            canvas.hiliteElements(
                animations
                    .filter(({ id }) => selectedAnimationIds.includes(id))
                    .filter(({ element }) => !!element)
                    .map(({ element }) => element)
            );
        }
    }

    handleMouseLeaveTimeline = () => {
        const { canvas } = this.props;
        const { draggedAnimationId } = this.state;

        this.setState({ isHoveringOverTimeline: false });

        if (!draggedAnimationId) {
            canvas.dimHilitedElements();
        }
    }

    render() {
        const {
            isAnimating,
            animatingAnimationIds,
            canvas,
            animations
        } = this.props;
        const {
            hideDisabledAnimations,
            draggedAnimationId,
            dragDropTargetAnimationId,
            dragHandlePosition,
            selectedAnimationIds
        } = this.state;

        animations.forEach(({ id }) => {
            if (!this.animationsRefs[id]) {
                this.animationsRefs[id] = React.createRef();
            }
        });

        const draggedAnimation = animations.find(({ id }) => draggedAnimationId == id);
        const isDraggingAnimation = !!draggedAnimationId;
        const draggedAnimationWidth = isDraggingAnimation ? this.animationsBounds[draggedAnimationId].width : 0;

        const dimmedWaitForClickAnimationIds = [];
        let hasLeadingWaitForClick = false;
        animations.forEach(animation => {
            if (animation.isCustom && animation.waitForClick) {
                if (hasLeadingWaitForClick) {
                    dimmedWaitForClickAnimationIds.push(animation.id);
                } else {
                    hasLeadingWaitForClick = true;
                }
            } else {
                hasLeadingWaitForClick = false;
            }
        });

        return (
            <TimelineContainer>
                <TimelineHeader>timeline
                    <FlexSpacer />
                    <TimelineControlsContainer
                        onClick={() => this.setState(({ hideDisabledAnimations }) => ({ hideDisabledAnimations: !hideDisabledAnimations }))}
                        disabled={isAnimating}
                    >
                        <Checkbox
                            checked={hideDisabledAnimations}
                            color="primary"
                            size="small"
                            disableRipple
                            style={{ padding: 0 }}
                        />
                        <div>hide disabled</div>
                    </TimelineControlsContainer>
                </TimelineHeader>
                <FakeTopPaddingBar />
                <TimelineAnimationsContainer
                    disabled={isAnimating}
                    onMouseEnter={this.handleMouseEnterTimeline}
                    onMouseLeave={this.handleMouseLeaveTimeline}
                    height={`${Math.min(window.innerHeight - 435, animations.length * 40 + 12)}px`}
                    ref={this.animationsContainerRef}
                >
                    {animations
                        .filter(({ disabled }) => (hideDisabledAnimations && !disabled) || !hideDisabledAnimations)
                        .map((animation, idx) => (
                            <TimelineAnimation
                                containerRef={this.animationsRefs[animation.id]}
                                key={`${animation.id}-${idx}`}
                                style={idx === 0 ? { marginTop: 12 } : null}
                                canvas={canvas}
                                animation={animation}
                                onDragStart={event => this.handleDragStart(event, animation.id)}
                                onDragEnd={() => this.handleDragEnd()}
                                onMouseMove={() => this.handleMouseMoveAnimation(animation)}
                                onMouseLeave={() => this.handleMouseLeaveAnimation(animation)}
                                dimmed={draggedAnimationId === animation.id || dimmedWaitForClickAnimationIds.includes(animation.id)}
                                disabled={isDraggingAnimation}
                                selected={
                                    isAnimating
                                        ? animatingAnimationIds.includes(animation.id)
                                        : !isDraggingAnimation && selectedAnimationIds.includes(animation.id)
                                }
                                showTopBar={dragDropTargetAnimationId === animation.id}
                                showBottomBar={(idx === animations.length - 1) && isDraggingAnimation && !dragDropTargetAnimationId}
                                onCreateCustomAnimation={customAnimation => this.handleCreateCustomAnimation(animation.id, customAnimation)}
                                onRemoveCustomAnimation={() => this.handleRemoveCustomAnimation(animation.id)}
                                width={"100%"}
                            />
                        ))
                    }
                    {isDraggingAnimation &&
                        <DraggedAnimationContainer left={dragHandlePosition.x} top={dragHandlePosition.y}>
                            <TimelineAnimation
                                canvas={canvas}
                                animation={draggedAnimation}
                                dimmed={false}
                                disabled={true}
                                isBeingDragged={true}
                                showTopBar={false}
                                selected={true}
                                width={`${draggedAnimationWidth}px`}
                            />
                        </DraggedAnimationContainer>
                    }
                </TimelineAnimationsContainer>
            </TimelineContainer>
        );
    }
}

export function AnimationCustomTimelineEditor({
    canvas,
    animations,
    isAnimating,
    animatingAnimationIds,
    generalAnimationsSettings
}) {
    const setAnimationsArrangementType = async arrangementType => {
        canvas.updateGeneralAnimationsSettings({ arrangementType });
        await canvas.saveCanvasModel();
    };

    return (
        <Container>
            <Gap10 />
            <Timeline
                isAnimating={isAnimating}
                animatingAnimationIds={animatingAnimationIds}
                canvas={canvas}
                animations={animations}
                generalAnimationsSettings={generalAnimationsSettings}
            />
            <Gap10 />
            <AnimationArrangementControl
                isAnimating={isAnimating}
                animationsArrangementType={generalAnimationsSettings.arrangementType}
                setAnimationsArrangementType={arrangementType => setAnimationsArrangementType(arrangementType)}
            />
        </Container>
    );
}
