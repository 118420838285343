import React, { Component } from "react";
import styled from "styled-components";
import { ReactMic } from "react-mic";
import { CircularProgress, LinearProgress } from "@material-ui/core";

import {
    CloudDownload as CloudDownloadIcon,
    Mic as MicIcon,
    Delete as DeleteIcon,
    Stop as StopIcon,
    Save as SaveIcon,
    PlayCircleFilled as PlayIcon,
} from "@material-ui/icons";

import Waveform from "../Waveform";
import SavingAudioDialog from "./SavingAudioDialog";

import {
    ShowErrorDialog
} from "js/react/components/Dialogs/BaseDialog";
import { ds } from "js/core/models/dataService";
import getLogger, { LogGroup } from "js/core/logger";
import { AssetType, TaskType, TaskState } from "common/constants";
import { uploadFileAndCreateTask } from "js/core/services/tasks";
import { ShowWarningDialog, ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import { Key } from "js/core/utilities/keys";

const logger = getLogger(LogGroup.AUDIO);

const RECORDED_AUDIO_NAME = "recorded-audio";

const Container = styled.div`
    width: 100%;
`;

const WaveformAndControlsContainer = styled.div`
    width: 100%;
    background: #ffffff;
    padding: 10px;
`;

const WaveformContainer = styled.div.attrs(({ height = 80 }) => ({
    style: {
        height: `${height}px`
    }
}))`
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
    background: #ffffff;
    border: 1px solid #EEEEEE;
`;

const WaveformPreloader = styled(LinearProgress)`
    &&& {
        position: absolute;
        left: 40px;
        top: 50%;
        width: calc(100% - 80px);
        height: 2px;
        transform: translateY(-1px);
    }
`;

const WaveformPlaceholder = styled.div`
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-1px);
    width: calc(100% - 80px);
    height: 2px;
    background: #11a9e2;
`;

const AudioSizeNoteText = styled.div`
    margin-top: auto;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 300;
    color: #333333;
    position: absolute;
    bottom: 0;
`;

const AudioDescriptionContainer = styled.div`
    position: absolute;
    bottom: ${({ centerPositioned }) => centerPositioned ? "50%" : "6px"};
    transform: ${({ centerPositioned }) => centerPositioned ? "translateY(50%)" : "none"};
    right: 6px;
    width: calc(100% - 12px);
    z-index: 9999;
    color:${({ hasError }) => hasError ? "#ff0000" : "#777"};

    display: flex;
    justify-content: space-between;
    align-items: center;

    text-transform: uppercase;
    font-size: 10px;
    font-weight: 300;
    font-family: monospace;

    >span {
        max-width: ${({ hasError }) => hasError ? "unset" : "45%"};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

function AudioDescription({ name = "", duration = "", errorMessage = "", centerPositioned = false }) {
    return (
        <AudioDescriptionContainer centerPositioned={centerPositioned} hasError={!!errorMessage}>
            {errorMessage && <span>{errorMessage}</span>}
            {!errorMessage && <>
                <span>{name}</span>
                <span>{duration}</span>
            </>}
        </AudioDescriptionContainer>
    );
}

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin-top: 15px;
    width: 100%;
    height: 75px;
`;

const VerticalButtonContainer = styled.div.attrs(({ disabled }) => ({
    style: {
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.6 : 1,
        cursor: disabled ? "default" : "pointer"
    }
}))`
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    padding: 6px 8px;
    width: 62px;

    >div {
        margin-top: 8px;
        display: flex;
        flex-flow: row;

        >span {
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 400;
            color: #333;
            display: block;
            margin-right: 3px;
            white-space: nowrap;
        }

        >span:first-child {
            display: none;
        }

        >span:last-child {
            margin-right: 0;
        }        
    }

    &:hover {
        opacity: 0.9 !important;

        >div>span:first-child {
            display: ${({ displayHoldTo }) => displayHoldTo ? "inline" : "none"};
        }

        >div>span:nth-child(n+3) {
            display: ${({ displayHoldTo }) => displayHoldTo ? "none" : "block"};
        }
    }
`;

const VerticalButtonIconContainer = styled.div.attrs(({ backgroundColor }) => ({
    style: {
        backgroundColor
    }
}))`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 3px;
`;

function VerticalButton({
    Icon,
    text,
    onClick,
    disabled = false,
    iconColor = "#11a9e2",
    backgroundColor = "transparent",
    fetching = false,
}) {
    const iconSize = backgroundColor === "transparent" ? 24 : 18;

    return (
        <VerticalButtonContainer onClick={onClick} disabled={disabled}>
            <VerticalButtonIconContainer backgroundColor={backgroundColor}>
                {!fetching && <Icon style={{ color: iconColor, fontSize: iconSize }} />}
                {fetching && <CircularProgress size={iconSize} style={{ color: iconColor }} />}
            </VerticalButtonIconContainer>
            <div>
                <span></span>
                <span>{text}</span>
            </div>
        </VerticalButtonContainer>
    );
}

class PressAndHoldVerticalButton extends Component {
    constructor() {
        super();

        this.state = {
            pressedProgress: 0,
            isPressed: false
        };

        this.pressedAt = null;
        this.pressedProgressInterval = null;
    }

    onMouseDown = () => {
        const { holdToClickMs = 1000 } = this.props;

        clearInterval(this.pressedProgressInterval);
        this.pressedAt = Date.now();

        this.setState({ isPressed: true }, () => {
            this.pressedProgressInterval = setInterval(() => {
                const pressedProgress = Math.min((Date.now() - this.pressedAt) / holdToClickMs, 1);
                this.setState({ pressedProgress }, () => {
                    if (pressedProgress === 1) {
                        this.onMouseUpOrLeave();
                    }
                });
            }, 100);
        });
    }

    onMouseUpOrLeave = () => {
        const { onClick } = this.props;
        const { isPressed, pressedProgress } = this.state;
        if (!isPressed) {
            return;
        }

        clearInterval(this.pressedProgressInterval);
        this.pressedAt = null;

        if (pressedProgress === 1) {
            onClick();
        }

        this.setState({ isPressed: false, pressedProgress: 0 });
    }

    render() {
        const {
            Icon,
            text,
            disabled = false,
            iconColor = "#11a9e2",
            backgroundColor = "transparent",
            fetching = false
        } = this.props;
        const {
            pressedProgress,
            isPressed
        } = this.state;

        const iconSize = backgroundColor === "transparent" ? 24 : 18;

        return (
            <VerticalButtonContainer
                onMouseDown={this.onMouseDown}
                onMouseLeave={this.onMouseUpOrLeave}
                onMouseUp={this.onMouseUpOrLeave}
                disabled={disabled}
                displayHoldTo
            >
                <VerticalButtonIconContainer hilited={false} backgroundColor={backgroundColor}>
                    {!fetching &&
                        <>
                            {!isPressed && <Icon style={{ color: iconColor, fontSize: iconSize }} />}
                            {isPressed && <CircularProgress size={iconSize} style={{ color: "#ff0000" }} variant="determinate" value={Math.min(pressedProgress * 120, 100)} />}
                        </>
                    }
                    {fetching && <CircularProgress size={iconSize} style={{ color: iconColor }} />}
                </VerticalButtonIconContainer>
                <div>
                    <span>Hold to</span>
                    {text.split(" ").map((text, idx) => <span key={idx}>{text}</span>)}
                </div>
            </VerticalButtonContainer>
        );
    }
}

const ControlButtonElement = styled.div.attrs(({ disabled }) => ({
    style: {
        pointerEvents: disabled ? "none" : "auto",
        border: `1px solid ${disabled ? "#a9a9a9" : "#11a9e2"}`
    }
}))`
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    cursor: pointer;

    >span {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #222;
        margin-left: 10px;
    }

    &:hover {
        opacity: 0.9;
    }
`;

function ControlButton({
    labelText,
    Icon,
    onClick,
    style = {},
    fetching = false,
    disabled = false,
}) {
    return (
        <ControlButtonElement onClick={onClick} disabled={disabled} style={style}>
            {!fetching && <Icon style={{ color: disabled ? "#a9a9a9" : "#11a9e2", fontSize: 24 }} />}
            {fetching && <CircularProgress size={24} style={{ color: "#11a9e2" }} />}
            <span>{labelText}</span>
        </ControlButtonElement>
    );
}

const ReactMicContainer = styled.div.attrs(({ hidden }) => ({
    style: {
        opacity: hidden ? 0 : 1
    }
}))`
    position: absolute;
    height: 100%;
    width: 100%;
`;

const ReactMicStyled = styled(ReactMic)`
    height: 100%;
    width: 100%;
`;

function padZeros(number, length) {
    let string = `${number}`;
    while (string.length < length) {
        string = `0${string}`;
    }
    return string;
}

function formatDuration(durationSeconds) {
    let seconds = durationSeconds;
    const hours = Math.floor(seconds / (60 * 60));
    seconds -= hours * 60 * 60;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return `${padZeros(hours, 2)}:${padZeros(minutes, 2)}:${seconds.toFixed(3)}`;
}

export class AudioEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            audioUrl: null,
            audioName: null,
            audioDuration: null,
            isFetchingAudio: true,
            isAudioFailedToLoad: false,
            isImportingAudio: false,
            isRecordingAudio: false,
            isInRecordingMode: false,
            isUploadingRecordedAudio: false,
            isPlayingAudio: false,
            audioRecordPermission: "denied",
        };

        this.recordedAudioFile = null;
        this.actualRecordedAudioMimeType = null;
        this.recordingDurationSeconds = 0;
        this.recordingTimer = null;

        this.hasUploadedAudio = false;

        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyDown);

        this.loadAudio();
        this.loadAudioRecordPermission();
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);

        if (this.uploadFileAndCreateTaskPromise) {
            ShowDialogAsync(SavingAudioDialog, {
                uploadFileAndCreateTaskPromise: this.uploadFileAndCreateTaskPromise
            });
        }
    }

    onKeyDown = event => {
        const { isRecordingAudio, isPlayingAudio, isImportingAudio } = this.state;

        if (event.which !== Key.ESCAPE) {
            return;
        }

        if (isRecordingAudio || isPlayingAudio || isImportingAudio) {
            event.stopPropagation();

            if (isRecordingAudio) {
                this.handleStopRecording();
            } else if (isPlayingAudio) {
                this.onAudioFinishedPlaying();
            }
        }
    }

    loadAudioRecordPermission = async () => {
        let permission;
        if (navigator.permissions && navigator.permissions.query) {
            permission = await navigator.permissions.query({ name: "microphone" });
        }

        const permissionState = permission?.state ?? "granted";
        this.setState({ audioRecordPermission: permissionState });

        if (permissionState === "denied" || permissionState === "prompt") {
            // Force the browser to show the mic option in toolbar
            navigator.mediaDevices.getUserMedia({ audio: true });
        }

        if (permission) {
            permission.onchange = event => {
                this.setState({ audioRecordPermission: event.target.state });
            };
        }
    }

    loadAudio = async () => {
        const { canvas } = this.props;

        try {
            // Make sure we refresh w/o audioUrl to unmount the Waveform component
            await new Promise(resolve => this.setState({
                audioUrl: null,
                audioName: null,
                isFetchingAudio: true,
                isAudioFailedToLoad: false
            }, resolve));

            const audioAssetId = canvas.dataModel.get("audioAsset");
            if (!audioAssetId) {
                this.setState({ isFetchingAudio: false });
                return;
            }

            const asset = await ds.assets.getAssetById(audioAssetId, AssetType.AUDIO);
            const audioUrl = await asset.getBaseUrl();
            const audioName = asset.get("name");
            this.setState({ audioUrl, audioName });
        } catch (err) {
            logger.error(err, "AudioEditor loadAudio() failed", { slideId: canvas.dataModel.id });
        }
    }

    onAudioLoaded = ({ durationSeconds }) => {
        this.setState({ isFetchingAudio: false, isAudioFailedToLoad: false, audioDuration: formatDuration(durationSeconds) });
    }

    onAudioLoadFailed = () => {
        this.setState({ isFetchingAudio: false, isAudioFailedToLoad: true });

        if (this.hasUploadedAudio) {
            ShowErrorDialog({
                title: "Failed to load audio",
                message: "Please try another audio file"
            });
        }
    }

    onAudioFinishedPlaying = () => {
        this.setState({ isPlayingAudio: false });
    }

    handlePlayAudio = () => {
        this.setState({ isPlayingAudio: true });
    }

    handleImportAudio = () => {
        const { isInRecordingMode } = this.state;
        if (isInRecordingMode) {
            // Force exit record mode
            this.setState({ isInRecordingMode: false });
        }

        this.fileInputRef.current.click();
    }

    handleFileSelected = async event => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        this.setState({ isImportingAudio: true });
        await this.uploadAndTranscodeAudio(file);
        this.loadAudio();
        this.setState({ isImportingAudio: false });
    }

    checkFileSize = file => {
        const maxAudioSizeBytes = 10 * 1024 * 1024; // 10 MB

        if (file.size > maxAudioSizeBytes) {
            throw new Error("File is too large to upload");
        }
    }

    uploadAndTranscodeAudio = async file => {
        const { canvas } = this.props;

        let assetId;
        try {
            this.checkFileSize(file);

            this.uploadFileAndCreateTaskPromise = new Promise((resolve, reject) => {
                uploadFileAndCreateTask(
                    file,
                    TaskType.AUDIO_UPLOAD,
                    task => {
                        switch (task.state) {
                            case TaskState.ERROR:
                                return reject(new Error(task.errorMessage));
                            case TaskState.FINISHED:
                                resolve(task.assetId);
                        }
                    },
                    {
                        name: file.name.includes(".") ? file.name.split(".").slice(null, -1).join(".") : file.name
                    })
                    .catch(err => reject(err));
            });
            assetId = await this.uploadFileAndCreateTaskPromise;
            this.hasUploadedAudio = true;
            this.uploadFileAndCreateTaskPromise = null;
        } catch (err) {
            ShowWarningDialog({
                title: "Failed to process audio",
                message: err.message,
            });
            return;
        }

        canvas.dataModel.update({
            audioAsset: assetId
        });
    }

    handleEnterRecordingMode = () => {
        return new Promise(resolve => this.setState({ isInRecordingMode: true }, resolve));
    }

    handleExitRecordingMode = () => {
        this.recordedAudioFile = null;
        this.actualRecordedAudioMimeType = null;

        this.setState({ isInRecordingMode: false });
        this.loadAudio();
    }

    handleStartRecording = () => {
        if (this.soundtrack) {
            this.handleStopPreview();
        }

        this.recordedAudioFile = null;
        this.actualRecordedAudioMimeType = null;

        this.setState({ isRecordingAudio: true, audioUrl: null, audioName: RECORDED_AUDIO_NAME, audioDuration: formatDuration(0) });

        this.recordingDurationSeconds = 0;
        this.recordingTimer = setInterval(() => {
            this.recordingDurationSeconds += 0.01;
            this.setState({ audioDuration: formatDuration(this.recordingDurationSeconds) });
        }, 10);
    }

    handleStopRecording = () => {
        clearInterval(this.recordingTimer);

        this.setState({ isRecordingAudio: false, isUploadingRecordedAudio: true });
    }

    onReactMicRecordingStopped = async recordedAudioFile => {
        const { canvas } = this.props;
        const { isUploadingRecordedAudio } = this.state;

        this.recordedAudioFile = recordedAudioFile;

        this.setState({ audioUrl: null }, () =>
            this.setState({ audioUrl: this.recordedAudioFile.blobURL })
        );

        if (isUploadingRecordedAudio) {
            try {
                const file = new File([this.recordedAudioFile.blob], RECORDED_AUDIO_NAME, { type: this.actualRecordedAudioMimeType });
                await this.uploadAndTranscodeAudio(file);
                this.loadAudio();
                this.setState({ isUploadingRecordedAudio: false });
                this.handleExitRecordingMode();
            } catch (err) {
                logger.error(err, "AudioEditor onReactMicRecordingStopped() failed", { slideId: canvas.dataModel.id });
            }
        }
    }

    onReactMicData = recordedAudioFile => {
        this.actualRecordedAudioMimeType = recordedAudioFile.type;
    }

    handleDeleteAudio = () => {
        const { canvas } = this.props;

        canvas.dataModel.update({
            audioAsset: null
        });

        this.loadAudio();
    }

    handlePreview = () => {
        const { audioUrl } = this.state;

        this.soundtrack = new Audio(audioUrl);

        this.soundtrack.addEventListener("ended", () => {
            this.handleStopPreview();
        });
        this.soundtrack.play();

        this.setState({ isPlaying: true });
    }

    handleStopPreview = () => {
        this.soundtrack.pause();
        this.soundtrack.currentTime = 0;

        this.soundtrack = null;

        this.setState({ isPlaying: false });
    }

    render() {
        const {
            isReadOnly,
            isAnimating
        } = this.props;
        const {
            audioUrl,
            audioName,
            audioDuration,
            isFetchingAudio,
            isImportingAudio,
            isRecordingAudio,
            isPlayingAudio,
            isInRecordingMode,
            isUploadingRecordedAudio,
            audioRecordPermission,
            isPlaying,
            isAudioFailedToLoad
        } = this.state;

        const hasRecordedAudio = audioName === RECORDED_AUDIO_NAME;

        return (<Container>
            {(isInRecordingMode || hasRecordedAudio || !audioUrl) && <>
                <WaveformAndControlsContainer>
                    <WaveformContainer>
                        {isInRecordingMode &&
                            <ReactMicContainer hidden={!isRecordingAudio}>
                                <ReactMicStyled
                                    record={isRecordingAudio}
                                    onStop={this.onReactMicRecordingStopped}
                                    onData={this.onReactMicData}
                                    strokeColor="#11a9e2"
                                    backgroundColor="white"
                                    mimeType="audio/webm;codecs=opus" // Safari ignores this and records MP4-wrapped AAC
                                />
                                <AudioDescription duration={audioDuration} />
                            </ReactMicContainer>
                        }
                        {!isRecordingAudio && <>
                            {audioUrl && <>
                                <Waveform
                                    src={audioUrl}
                                    height={80}
                                    waveColor="#ccc"
                                    cursorColor="#ffffff"
                                    interact={false}
                                    fillParent={true}
                                    isPlaying={isPlayingAudio}
                                    onLoaded={this.onAudioLoaded}
                                    onLoadFailed={this.onAudioLoadFailed}
                                    onFinishedPlaying={this.onAudioFinishedPlaying}
                                />
                                {!isFetchingAudio && <AudioDescription duration={audioDuration} />}
                            </>}
                            {isFetchingAudio && <WaveformPreloader />}
                            {!isFetchingAudio && !audioUrl && <WaveformPlaceholder />}
                        </>}
                    </WaveformContainer>
                    <ButtonsContainer>
                        {isInRecordingMode && <>
                            {isRecordingAudio &&
                                <VerticalButton
                                    text="Stop"
                                    Icon={StopIcon}
                                    iconColor="#ffffff"
                                    backgroundColor="#fc7263"
                                    onClick={this.handleStopRecording}
                                />
                            }
                            {!isRecordingAudio && <>
                                <VerticalButton
                                    text={"Saving..."}
                                    Icon={SaveIcon}
                                    iconColor="#ffffff"
                                    backgroundColor="#7fb314"
                                    fetching={true}
                                    disabled={true}
                                />
                            </>}
                        </>}
                        {!isInRecordingMode && <>
                            {audioUrl && <>
                                {/*<PressAndHoldVerticalButton*/}
                                {/*    text="Discard"*/}
                                {/*    Icon={DeleteIcon}*/}
                                {/*    iconColor="#666666"*/}
                                {/*    onClick={this.handleDeleteAudio}*/}
                                {/*    disabled={isAnimating || isPlayingAudio || isImportingAudio || isFetchingAudio || isReadOnly || !audioUrl}*/}
                                {/*/>*/}
                                {!isPlaying &&
                                    <VerticalButton
                                        text={"Preview"}
                                        Icon={PlayIcon}
                                        onClick={this.handlePreview}
                                        disabled={isAnimating || audioRecordPermission !== "granted" || isPlayingAudio || isImportingAudio || isFetchingAudio || isReadOnly}
                                    />
                                }
                                {isPlaying &&
                                    <VerticalButton
                                        text={"Stop"}
                                        Icon={StopIcon}
                                        onClick={this.handleStopPreview}
                                        disabled={isAnimating || audioRecordPermission !== "granted" || isPlayingAudio || isImportingAudio || isFetchingAudio || isReadOnly}
                                    />
                                }
                                <VerticalButton
                                    text={"Record take"}
                                    Icon={MicIcon}
                                    onClick={() =>
                                        this.handleEnterRecordingMode()
                                            .then(() => this.handleStartRecording())
                                    }
                                    disabled={isAnimating || audioRecordPermission !== "granted" || isPlayingAudio || isImportingAudio || isFetchingAudio || isReadOnly}
                                />
                            </>}
                            {!audioUrl &&
                                <VerticalButton
                                    text={"Record"}
                                    Icon={MicIcon}
                                    onClick={() =>
                                        this.handleEnterRecordingMode()
                                            .then(() => this.handleStartRecording())
                                    }
                                    disabled={isAnimating || audioRecordPermission !== "granted" || isPlayingAudio || isImportingAudio || isFetchingAudio || isReadOnly}
                                />
                            }
                        </>}
                    </ButtonsContainer>
                </WaveformAndControlsContainer>
                <ControlButton
                    Icon={CloudDownloadIcon}
                    fetching={isImportingAudio}
                    labelText={`Import${isImportingAudio ? "ing" : ""} Audio File`}
                    disabled={isAnimating || isUploadingRecordedAudio || isPlayingAudio || isImportingAudio || isRecordingAudio || isFetchingAudio || isReadOnly}
                    style={{ marginTop: 20 }}
                    onClick={this.handleImportAudio}
                />
            </>}
            {!isInRecordingMode && !hasRecordedAudio && audioUrl && <>
                <ControlButton
                    Icon={MicIcon}
                    labelText="Record Audio"
                    disabled={isAnimating || isUploadingRecordedAudio || isPlayingAudio || isImportingAudio || isRecordingAudio || isFetchingAudio || isReadOnly}
                    style={{ marginTop: 10, marginBottom: 20 }}
                    onClick={() =>
                        this.handleEnterRecordingMode()
                            .then(() => this.handleStartRecording())
                    }
                />
                <WaveformAndControlsContainer>
                    <WaveformContainer height={21}>
                        <Waveform
                            src={audioUrl}
                            height={21}
                            waveColor="#f9f9f9"
                            cursorColor="#ffffff"
                            interact={false}
                            fillParent={true}
                            isPlaying={isPlayingAudio}
                            onLoaded={this.onAudioLoaded}
                            onLoadFailed={this.onAudioLoadFailed}
                            onFinishedPlaying={this.onAudioFinishedPlaying}
                        />
                        {!isFetchingAudio && <>
                            {!isAudioFailedToLoad && <AudioDescription name={audioName} duration={audioDuration} centerPositioned />}
                            {isAudioFailedToLoad && <AudioDescription errorMessage="Failed to load audio" centerPositioned />}
                        </>}
                    </WaveformContainer>
                    <ButtonsContainer>
                        <PressAndHoldVerticalButton
                            text="Discard"
                            Icon={DeleteIcon}
                            iconColor="#666666"
                            onClick={this.handleDeleteAudio}
                            disabled={isAnimating || isPlayingAudio || isImportingAudio || isFetchingAudio || isReadOnly}
                        />
                        <VerticalButton
                            text={"Import new"}
                            Icon={CloudDownloadIcon}
                            fetching={isImportingAudio}
                            onClick={this.handleImportAudio}
                            disabled={isAnimating || isPlayingAudio || isImportingAudio || isFetchingAudio || isReadOnly}
                        />
                    </ButtonsContainer>
                </WaveformAndControlsContainer>
            </>}
            <input
                ref={this.fileInputRef}
                type="file"
                hidden
                accept="audio/*"
                onChange={this.handleFileSelected}
            />
            <AudioSizeNoteText>NOTE: Max upload size is 10mb.</AudioSizeNoteText>
        </Container >);
    }
}
