import React, { Component } from "react";
import styled from "styled-components";
import { BlockLibrary } from "js/editor/DocumentEditor/Components/BlockLibrary";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgb(51, 51, 51);
  display: flex;
  padding: 20px;
`;

export class CollaborationBar extends Component {
    render() {
        return (
            <Container>
            </Container>
        );
    }
}

