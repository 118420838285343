import { Button, Icon } from "@material-ui/core";
import moment from "moment";
import React, { Component, Fragment } from "reactn";

import { APP_UNLIMITED_SLIDE_COUNT, CustomerType, SubscriptionStatus, TYPEFORM_IDS } from "common/constants";
import { getStaticUrl } from "js/config";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { openPricingPage, openTeamUpgrade } from "js/core/utilities/externalLinks";
import { getPricingPageUrl } from "js/core/utilities/pricing";
import { app } from "js/namespaces";
import { FlexBox, GridBox } from "js/react/components/LayoutGrid";
import {
    BlueButton,
    DarkButton,
    UIPane,
    UIPaneContents,
    UIPaneHeader,
    YellowButton
} from "js/react/components/UiComponents";
import { CancelSubscribtionMessage, NextPaymentSection } from "js/react/views/UserOptions/components/NextPaymentSection";
import EndTrialDialog from "js/react/views/UserOptions/dialogs/EndTrialDialog";
import UpdateBillingAddressDialog from "js/react/views/UserOptions/dialogs/UpdateBillingAddressDialog";
import { ShowConfirmationDialog, ShowDialog, ShowDialogAsync, ShowErrorDialog } from "../../../components/Dialogs/BaseDialog";
import { Gap20 } from "../../../components/Gap";
import Loadable from "../../../components/Loadable";
import ChangePaymentMethodDialog from "../Billing/ChangePaymentMethodDialog";
import TransactionsTable from "../Billing/TransactionsTable";
import BillingDataService from "../dataservices/billingDataService";
import ChangePlanDialog from "../dialogs/ChangePlanDialog";

import "css/billing.scss";
import { ShowTypeformDialog } from "js/react/components/Dialogs/TypeformDialog";

const logger = getLogger(LogGroup.BILLING);

const BlueCheck = () => {
    return (
        <Icon
            style={{
                fontSize: 18,
                fontWeight: 600,
                color: "#11A9E2",
            }}
        >check</Icon>
    );
};

class BillingPane extends Component {
    _isMounted = false;

    initialState = {
        isLoading: true,
        subscription: null,
        managedTeam: null,
        transactionHistoryData: [],
        availableReferralCredit: 0,
        charge: null
    };

    state = { ...this.initialState };

    get hasSubscription() {
        const { subscription } = this.state;
        return subscription != null && subscription.product != "basic" && subscription.id != null;
    }

    get isInTrial() {
        const { subscription } = this.state;
        return subscription && subscription.status == "trialing";
    }

    get nextInterval() {
        const { subscription } = this.state;
        let nextPhase = null;
        if (subscription.schedule) {
            const currentPhaseStateDate = subscription.schedule.current_phase.start_date;
            const currentPhaseIndex = subscription.schedule.phases.findIndex(phase => phase.start_date == currentPhaseStateDate);
            if (subscription.schedule.phases.length > 1) {
                nextPhase = subscription.schedule.phases[currentPhaseIndex + 1];
            }
        }

        let nextInterval = subscription.interval;
        if (nextPhase) {
            nextInterval = nextPhase.plans[0].plan.interval;
        }

        return nextInterval;
    }

    get isBillingManagedByTeam() {
        return this.state.managedTeam != null;
    }

    get canceledSubscription() {
        const { subscription } = this.state;
        return subscription.cancel_at_period_end;
    }

    async componentDidMount() {
        const { organizationId } = this.props;

        this._isMounted = true;
        await this.loadBillingData();

        const { subscription } = this.state;
        if (!subscription) {
            return;
        }

        if (subscription.product === "enterprise" && subscription.status === "active" && !subscription.customerAddress) {
            ShowDialog(UpdateBillingAddressDialog, {
                name: subscription.customerName,
                address: subscription.customerAddress,
                title: "Please provide your billing address",
                canCancel: false,
                onAccept: this.handleCustomerAddressUpdate,
                isOrganization: !!organizationId
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.organizationId !== this.props.organizationId) {
            this.setState(this.initialState, async () => {
                await this.loadBillingData();
            });
        }
    }

    async fetchManagedTeam() {
        await ds.teams.loadModels();

        const userTeams = ds.teams.models;
        if (!userTeams) {
            return;
        }

        let managedTeam;
        for (const team of userTeams) {
            const teamMembers = team.get("members");
            if (teamMembers[app.user.id] && teamMembers[app.user.id].hasSeat) {
                managedTeam = team;
                break;
            }
        }

        if (managedTeam) {
            this.setState({
                managedTeam: managedTeam.get("name")
            });
        }
    }

    loadBillingData = async () => {
        if (!this._isMounted) {
            return;
        }

        const { organizationId } = this.props;

        this.setState({ isLoading: true });

        try {
            const [paymentMethod, quota, subscription, pastDueData] = await Promise.all([
                BillingDataService.fetchPaymentMethod(organizationId),
                BillingDataService.fetchQuotas(organizationId),
                BillingDataService.fetchSubscription(organizationId),
                Api.pastDue.get({ organizationId })
            ]);

            this.setState({
                paymentMethod,
                quota,
                subscription,
                charge: pastDueData.charge
            });

            await this.fetchManagedTeam();
            await this.getTransactionHistory();
            await this.getReferralCredit();

            this.setState({ isLoading: false });
        } catch (err) {
            logger.error(err, "loadBillingData() failed", { workspaceId: this.props.organizationId ?? "personal" });
        }
    }

    getTransactionHistory = async () => {
        try {
            const transactionHistoryData = await Api.transactions.get({ orgId: this.props.organizationId });
            this.setState({ transactionHistoryData: transactionHistoryData.body });
        } catch (err) {
            logger.error(err, "getTransactionHistory() failed", { workspaceId: this.props.organizationId ?? "personal" });
        }
    };

    getReferralCredit = async () => {
        try {
            const data = await Api.referrals.get();
            this.setState({ availableReferralCredit: data.totalAvailable });
        } catch (err) {
            logger.error(err, "getReferralCredit() failed", { workspaceId: this.props.organizationId ?? "personal" });
        }
    }

    handleChangePaymentMethod = async () => {
        ShowDialog(ChangePaymentMethodDialog, {
            orgId: this.props.organizationId,
            callback: success => {
                if (success) {
                    this.loadBillingData();
                }
            }
        });
    };

    handleEndTrial = () => {
        ShowDialogAsync(EndTrialDialog)
            .then(() => this.loadBillingData());
    };

    handleCancelSubscription = async event => {
        const { organizationId } = this.props;
        const { subscription } = this.state;
        const isOrganization = organizationId !== undefined;
        const isInDunning = subscription.status === SubscriptionStatus.PAST_DUE;
        const nextBillDate = moment.unix(subscription.current_period_end).format("MMM DD, YYYY");

        ShowConfirmationDialog({
            title: `Are you sure you want to cancel your ${this.isInTrial ? "trial" : organizationId ? "Team plan" : "Pro plan"}?`,
            message: CancelSubscribtionMessage({ isInDunning, nextBillDate, isInTrial: this.isInTrial, isOrganization }),
            cancelButtonLabel: "Never mind",
            okButtonLabel: `Cancel ${this.isInTrial ? "trial" : "plan"}`
        })
            .then(async accept => {
                if (!accept) {
                    return;
                }
                this.setState({ isLoading: true });
                await BillingDataService.cancelSubscription(subscription.id, organizationId, { immediate: isInDunning });
                this.loadBillingData();
                ShowTypeformDialog({
                    typeformId: TYPEFORM_IDS.CHURN_SURVEY,
                    hiddenFields: {
                        user_id: app.user.id,
                    },
                });
            })
            .catch(err => {
                logger.error(err, "handleCancelSubscription() failed", { subscriptionId: subscription?.id, workspaceId: organizationId ?? "personal" });

                ShowErrorDialog({
                    error: "An error occurred while canceling your subscription",
                    message: (
                        <Fragment>
                            <p>
                                <strong>Error: </strong>
                                {err.message}
                            </p>
                            <p>We apologize for the inconvenience. Please contact us at support@beautiful.ai.</p>
                        </Fragment>
                    )
                });
            });
    };

    showPricingPage = (currentPlan = "basic") => {
        window.open(getPricingPageUrl(app && app.user.hasTakenTrial, app && app.user.has("hasTakenTeamTrial"), currentPlan), "_blank");
    };

    handleReactiveSubscription = async () => {
        const { organizationId } = this.props;
        const { paymentMethod } = this.state;
        const customerType = organizationId ? CustomerType.TEAM : CustomerType.INDIVIDUAL;

        if (!paymentMethod) {
            ShowDialog(ChangePaymentMethodDialog, {
                orgId: organizationId,
                callback: async () => {
                    await Api.subscriptions.put({
                        orgId: organizationId,
                        customerType,
                        type: "reactive_subscription"
                    });
                    this.loadBillingData();
                }
            });
            return;
        }

        await Api.subscriptions.put({
            orgId: organizationId,
            customerType,
            type: "reactive_subscription"
        });
        return this.loadBillingData();
    };

    renderUpgradePlanButton() {
        const { organizationId } = this.props;
        const { subscription } = this.state;
        if (this.hasSubscription) {
            if (subscription && subscription.cancel_at_period_end) {
                const currentPlan = organizationId ? CustomerType.TEAM : CustomerType.INDIVIDUAL;
                // in canceled subscription period
                return (
                    <YellowButton color="primary" onClick={this.handleReactiveSubscription}>
                        {currentPlan === "team" ? "Reactivate Team Plan" : "Reactivate Pro Plan"}
                    </YellowButton>
                );
            }
        } else {
            return (
                <YellowButton color="primary" onClick={() => this.showPricingPage()}>
                    Upgrade Plan
                </YellowButton>
            );
        }
        return null;
    }

    renderCancelPlanButton() {
        const { subscription } = this.state;

        if (!this.hasSubscription || this.canceledSubscription) {
            return null;
        }

        if (subscription?.product === "enterprise") {
            return (<div className="plan-info">
                Contact us at <a href="mailto:support@beautiful.ai">support@beautiful.ai</a> to cancel your Enterprise {this.isInTrial ? "trial" : "plan"}
            </div>);
        }

        if (this.isInTrial) {
            const subscriptionCreatedAt = moment(subscription.created * 1000);
            const canStartSubscriptionNow = subscriptionCreatedAt.isBefore(moment().subtract(10, "minute"));

            return (<>
                <Button onClick={this.handleCancelSubscription}>
                    Cancel free trial
                </Button>
                {canStartSubscriptionNow && <BlueButton onClick={this.handleEndTrial}>
                    Start Subscription Now
                </BlueButton>}
            </>);
        }

        return (<Button
            variant="contained"
            color="grey"
            onClick={this.handleCancelSubscription}
        >
            Cancel plan
        </Button>);
    }

    renderPlanDescription() {
        const { subscription, quota, isLoading } = this.state;

        if (isLoading) return null;

        if (subscription && subscription.id) {
            let daysRemainingInTrial = Math.ceil(
                (subscription.trialEndsTimestamp * 1000 - new Date().getTime()) / 1000 / 60 / 60 / 24
            );
            const price = subscription.items.data[0].price;
            const isRegularPricing = price.nickname.startsWith("Team-") || price.nickname.startsWith("Pro-");

            let interval = `${subscription.interval}ly`;
            if (interval === "yearly") {
                interval = "annual";
            }
            return (
                <Fragment>
                    <div className="plan">
                        {subscription.product}
                        <span className="plan-period">({interval})</span>
                        {subscription.cancel_at_period_end && <span className="canceled-notice">Canceled</span>}
                    </div>
                    {((subscription.status == "trialing" || subscription.status == "active") && !subscription.cancel_at_period_end) && (
                        <>
                            <div style={{
                                fontWeight: 900,
                                fontSize: 14,
                                color: "#828282",
                                marginTop: 10
                            }}>
                                {subscription.interval !== this.nextInterval && (
                                    <span>Scheduled to switch to monthly billing</span>
                                )}
                            </div>
                            {isRegularPricing && (
                                <div
                                    onClick={() =>
                                        ShowDialog(ChangePlanDialog,
                                            {
                                                subscription,
                                                nextInterval: this.nextInterval,
                                                callback: () => this.loadBillingData(),
                                                orgId: this.props.organizationId
                                            })
                                    }
                                    style={{
                                        color: "#159BCD",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        fontWeight: 900,
                                        fontSize: 14
                                    }}
                                >
                                    {this.nextInterval === "year"
                                        ? "Switch to monthly billing"
                                        : (subscription.interval === this.nextInterval
                                            ? "Save with"
                                            : "Switch back to") + " annual billing"
                                    }
                                </div>
                            )}
                        </>
                    )}
                    {subscription.status == "trialing" && (
                        <div className="plan-info">
                            You have {daysRemainingInTrial} {"day".pluralize(daysRemainingInTrial > 1)} remaining in
                            your free trial
                        </div>
                    )}
                </Fragment>
            );
        } else {
            if (this.props.organizationId === undefined) {
                let userPlan = ds.userPlans.models[0];
                let planName;
                if (userPlan.id == "-LfRhaqvuLMLJ0qs8aNJ" || userPlan.get("name") == "free") {
                    planName = "Basic";
                } else {
                    planName = userPlan.get("name");
                }

                return (
                    <Fragment>
                        <div className="plan">
                            {planName}
                            <span className="plan-period">(free)</span>
                        </div>
                        {
                            quota.slides.limit < APP_UNLIMITED_SLIDE_COUNT
                                ? <Fragment>
                                    <div className="plan-info">
                                        You have used {quota.slides.count} of your {quota.slides.limit} slide limit.
                                    </div>
                                </Fragment>
                                : null
                        }

                    </Fragment>
                );
            } else {
                return <div className="plan">None</div>;
            }
        }
    }

    handleCustomerEmailUpdate = async email => {
        const { organizationId } = this.props;
        const customerType = organizationId ? CustomerType.TEAM : CustomerType.INDIVIDUAL;
        await Api.subscriptions.put({
            customerType,
            email,
            orgId: organizationId,
            type: "update_customer_email",
        });
        this.loadBillingData();
    };

    handleCustomerAddressUpdate = async ({ address, name }) => {
        const { organizationId } = this.props;
        const customerType = organizationId ? CustomerType.TEAM : CustomerType.INDIVIDUAL;

        try {
            await Api.subscriptions.put({
                customerType,
                address,
                name,
                orgId: organizationId,
                type: "update_customer_address",
            });
        } catch (err) {
            logger.error(err, "handleCustomerAddressUpdate() failed", { workspaceId: organizationId ?? "personal" });

            ShowErrorDialog({
                title: "Error",
                message: <>Sorry, we couldn't save your changes. please contact us at <a href="mailto:support@beautiful.ai">support@beautiful.ai</a></>,
            });
        }

        this.loadBillingData();
    };

    render() {
        const { organizationId } = this.props;
        const { paymentMethod, subscription, transactionHistoryData, availableReferralCredit, charge, customer } = this.state;

        const isOrganization = !!organizationId;

        return (
            <UIPane className="billing">
                <UIPaneHeader>{!isOrganization ? "Billing" : "Team Billing"}</UIPaneHeader>
                <UIPaneContents>
                    <Loadable isLoading={this.state.isLoading}>
                        <div className="billing-blocks">
                            <div className="billing-block">
                                <div className="title">Current Plan</div>
                                <div
                                    className="contents"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        marginTop: 0,
                                        marginBottom: 0,
                                    }}
                                >{this.renderPlanDescription()}</div>
                                {!isOrganization && !this.hasSubscription && availableReferralCredit > 0 && (
                                    <div style={{ fontSize: "90%", paddingTop: 10 }}>
                                        <Icon style={{ color: "#fa0", fontSize: "1.25em", verticalAlign: "sub" }}>monetization_on</Icon>{" "}
                                        You have a <b>${availableReferralCredit / 100}</b> referral credit available!
                                    </div>
                                )}
                                <div className="actions">
                                    {this.renderUpgradePlanButton()}
                                    {this.renderCancelPlanButton()}
                                </div>
                            </div>

                            {!this.hasSubscription && (
                                <div className="billing-block">
                                    <div className="title">Your Team</div>
                                    <div className="contents">
                                        Team workspaces allow you to organize your team and control your brand.
                                    </div>
                                    <div className="actions">
                                        <DarkButton onClick={() => this.showPricingPage("basic")}>
                                            Invite your team
                                        </DarkButton>
                                    </div>
                                </div>
                            )}

                            {this.hasSubscription && (
                                <NextPaymentSection
                                    isTeam={isOrganization}
                                    subscription={subscription}
                                    orgId={organizationId}
                                    nextInterval={this.nextInterval}
                                    availableCredit={availableReferralCredit}
                                    paymentMethod={paymentMethod}
                                    reloadBillingData={this.loadBillingData}
                                    onChangePaymentMethod={this.handleChangePaymentMethod}
                                    onUpdateAddress={this.handleCustomerAddressUpdate}
                                    charge={charge}
                                />
                            )}

                            {subscription?.product !== "enterprise" &&
                                <div className="billing-block">
                                    <FlexBox
                                        column
                                        center
                                        fillHeight
                                        style={{
                                            fontSize: 14,
                                            color: "#676767",
                                        }}
                                    >
                                        <img
                                            src={getStaticUrl("/images/billing/billing-cta-banner.avif")}
                                            height="70px"
                                        />

                                        {
                                            subscription?.product === "team"
                                                ? <>
                                                    <div className="cta-title">Scale your team with our Enterprise plan.</div>
                                                    <Gap20 />
                                                    <div style={{ textAlign: "center" }}>
                                                        Perfect for larger organizations, with priority support and advanced
                                                        <br />
                                                        control, scalability and security.
                                                    </div>
                                                    <Gap20 />
                                                    <Button
                                                        color="primary"
                                                        onClick={() => openPricingPage(app.user.analyticsPersonalPlan, { cta: "BillingPane" })}
                                                    >Learn More</Button>
                                                </>
                                                : <>
                                                    <div className="cta-title">Work together, faster.</div>
                                                    <Gap20 />
                                                    <div>Organize your team, share content and control your brand.</div>
                                                    <Gap20 />
                                                    <GridBox
                                                        template="16px 200px 16px 200px"
                                                        gap="15px"
                                                    >
                                                        <BlueCheck />
                                                        <div>Collaborative Workspace</div>
                                                        <BlueCheck />
                                                        <div>Centralized Slide Library</div>
                                                        <BlueCheck />
                                                        <div>Custom Company Theme</div>
                                                        <BlueCheck />
                                                        <div>Custom Template Library</div>
                                                    </GridBox>
                                                    <Gap20 />
                                                    <BlueButton
                                                        onClick={() => openTeamUpgrade({ cta: "BillingPane" })}
                                                    >
                                                        Create a workspace
                                                    </BlueButton>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => openPricingPage(app.user.analyticsPersonalPlan, { cta: "BillingPane" })}
                                                    >Learn More</Button>
                                                </>
                                        }
                                    </FlexBox>
                                </div>
                            }
                        </div>

                        <Gap20 />
                        <TransactionsTable
                            data={transactionHistoryData}
                            onUpdateEmail={this.handleCustomerEmailUpdate}
                            subscription={subscription}
                        />
                    </Loadable>
                </UIPaneContents>
            </UIPane>
        );
    }
}

export default BillingPane;
