import GlobalState from "bai-react-global-state";
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";

export const docEditorState = new GlobalState({});

export class DocumentEditorController {
    static async loadDocument(documentId) {
        let document = await ds.presentations.getPresentation(documentId, "write");

        ds.selection.presentation = document;

        await document.prepareSlides(true);

        let theme = await app.themeManager.loadTheme(document);

        docEditorState.updateState({
            currentDocument: document,
            currentTheme: theme,
            currentPage: document.slides.at(0)
        });

        return document;
    }

    static setCurrentPage(page) {
        docEditorState.updateState({
            currentPage: page
        });
    }
}

app.mainView.editorView = {
    lockModel: {
        setLockState: () => {
        }
    },
    getContainerBounds: () => {
        return {
            canvasBounds: new geom.Rect(0, 0, 1280, 1656)
        };
    }
};
