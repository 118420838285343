import React, { useState, useRef } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { Popover } from "@material-ui/core";

import {
    Cancel as CancelIcon,
    Close as CloseIcon,
    PlayCircleFilled as PlayCircleFilledIcon,
    AddCircle as AddCircleIcon,
    Mouse as MouseIcon,
    TimerOutlined as TimerOutlinedIcon
} from "@material-ui/icons";

import { themeColors } from "js/react/sharedStyles";

import { DurationControl } from "../../DurationControl";

const AddCustomAnimationControlContainer = styled.div`
    position: absolute;
    width: 100%;
    // Wider for better hover uix
    height: 5px; 
    top: -3px;
    left: 0;
    z-index: 9999;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const AddCustomAnimationControlHorizontalLine = styled.div.attrs(({ hidden }) => ({
    style: {
        opacity: hidden ? 0 : 1
    }
}))`
    position: absolute;
    top: -1px;
    left: -5px;
    width: calc(100% + 5px);
    height: 0;
    border-top: 2px dotted ${themeColors.ui_blue};
`;

const AddCustomAnimationControlOpenPopoverButton = styled(AddCircleIcon).attrs(({ hidden }) => ({
    style: {
        opacity: hidden ? 0 : 1
    }
}))`
    &&& {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #ffffff;
        font-size: 17px;
        color: ${themeColors.ui_blue};
        border-radius: 50%;
    }
`;

const AddCustomAnimationControlPopoverContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    padding: 15px;

    div:first-child {
        margin-bottom: 15px;
    }
`;

const AddCustomAnimationControlPopoverButtonContainer = styled.div`
    width: 100%;
    cursor: pointer;

    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;

    >svg {
        color: #666;
        margin-right: 10px;
    }

    >div {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #222222;
    }
`;

function AddCustomAnimationControl({ onCreateCustomAnimation }) {
    const buttonRef = useRef();
    const [isHovering, setIsHovering] = useState(false);
    const [isPopoverOpen, setPopoverOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handlePopoverClose = () => {
        setPopoverOpen(false);
        setIsHovering(false);
    };

    const handleAddDelay = () => {
        onCreateCustomAnimation({
            id: uuid(),
            name: "Delay",
            duration: 1000,
            waitForClick: false
        });
    };

    const handleAddAdvanceOnClick = () => {
        onCreateCustomAnimation({
            id: uuid(),
            name: "Wait For Click",
            duration: 0,
            waitForClick: true
        });
    };

    return (
        <>
            <AddCustomAnimationControlContainer
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseDown={event => event.stopPropagation()}
            >
                <AddCustomAnimationControlHorizontalLine hidden={!isHovering && !isPopoverOpen} />
                <AddCustomAnimationControlOpenPopoverButton
                    hidden={!isHovering && !isPopoverOpen}
                    ref={buttonRef}
                    onClick={() => setPopoverOpen(true)}
                />
            </AddCustomAnimationControlContainer>
            <Popover
                anchorEl={buttonRef.current}
                open={isPopoverOpen}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <AddCustomAnimationControlPopoverContainer>
                    <AddCustomAnimationControlPopoverButtonContainer onMouseDown={event => event.stopPropagation()} onClick={handleAddDelay}>
                        <TimerOutlinedIcon /><div>Wait for Delay</div>
                    </AddCustomAnimationControlPopoverButtonContainer>
                    <AddCustomAnimationControlPopoverButtonContainer onMouseDown={event => event.stopPropagation()} onClick={handleAddAdvanceOnClick}>
                        <MouseIcon /><div>Wait for Click</div>
                    </AddCustomAnimationControlPopoverButtonContainer>
                </AddCustomAnimationControlPopoverContainer>
            </Popover>
        </>
    );
}

const TimelineAnimationContainer = styled.div.attrs(({ dimmed, disabled, width, backgroundColor, isCustom }) => ({
    style: {
        pointerEvents: disabled ? "none" : "auto",
        opacity: dimmed ? 0.3 : 1,
        backgroundColor,
        width,
        textTransform: isCustom ? "uppercase" : "none"
    }
}))`
    position: relative;
    height: 34px;

    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 6px;
  
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0,0,0,.1);

    // Overriding checkbox input sizing
    input {
        top: 0;
        left: 2px;
        width: 17px;
        height: 17px;
    }
  
    .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
`;

const TimelineAnimationName = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;

    >span {
        color: rgba(0,0,0,.75);
        font-weight: 600;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const TimelineAnimationTopBar = styled.div`
    position: absolute;
    top: -4px;
    left: -5px;
    width: calc(100% + 10px);
    height: 1px;
    background: ${themeColors.ui_blue};
`;

const TimelineAnimationBottomBar = styled.div`
    position: absolute;
    bottom: -2px;
    left: -5px;
    width: calc(100% + 10px);
    height: 1px;
    background: ${themeColors.ui_blue};
`;

const RemoveCustomAnimationIconButton = styled(CloseIcon)`
    &&& {
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translate(-50%, -50%);
        font-size: 18px;
        cursor: pointer;
    }
`;

export function TimelineAnimation({
    canvas,
    animation,
    onDragStart,
    onDragEnd,
    dimmed,
    disabled,
    selected,
    onMouseMove,
    onMouseLeave,
    isBeingDragged,
    showTopBar,
    showBottomBar,
    containerRef,
    onCreateCustomAnimation,
    onRemoveCustomAnimation,
    width,
    style = {}
}) {
    const handleDisabledChange = async disabled => {
        canvas.updateAnimation(animation.id, { disabled });
        await canvas.saveCanvasModel();
    };

    const handleDurationChange = async durationSeconds => {
        canvas.updateAnimation(animation.id, { duration: durationSeconds * 1000 });
        await canvas.saveCanvasModel();
    };

    const handleDragStart = event => {
        event.stopPropagation();
        onDragStart(event);
        const onMouseUp = () => {
            onDragEnd(event);
            window.removeEventListener("mouseup", onMouseUp);
        };
        window.addEventListener("mouseup", onMouseUp);
    };

    let backgroundColor = "#ffffff";
    if (animation.isCustom) {
        if (animation.waitForClick) {
            backgroundColor = "#fc7263";
        } else {
            backgroundColor = "#faba4a";
        }

        if (!selected) {
            backgroundColor = `${backgroundColor}cc`;
        }
    } else if (selected) backgroundColor = themeColors.darkHoverBlue;

    if (isBeingDragged) {
        backgroundColor = `${backgroundColor}aa`;
    }

    return (
        <TimelineAnimationContainer
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
            dimmed={dimmed}
            disabled={disabled}
            backgroundColor={backgroundColor}
            ref={containerRef}
            width={width}
            onMouseDown={handleDragStart}
            onClick={event => event.stopPropagation()}
            isCustom={animation.isCustom}
            style={style}
        >
            {showTopBar && <TimelineAnimationTopBar />}
            {!disabled && !showTopBar && <AddCustomAnimationControl onCreateCustomAnimation={onCreateCustomAnimation} />}
            {!animation.isCustom && animation.disabled &&
                <CancelIcon size="small" style={{ color: "#999" }}
                    onClick={() => handleDisabledChange(false)}
                    onMouseDown={event => event.stopPropagation()}
                />
            }
            {!animation.isCustom && !animation.disabled &&
                <PlayCircleFilledIcon size="small" style={{ color: themeColors.ui_blue }}
                    onClick={() => handleDisabledChange(true)}
                    onMouseDown={event => event.stopPropagation()}
                />}
            {animation.isCustom && animation.waitForClick &&
                <MouseIcon style={{ fontSize: 18, marginRight: 10 }} />
            }
            {animation.isCustom && !animation.waitForClick &&
                <TimerOutlinedIcon style={{ fontSize: 18, marginRight: 10 }} />
            }
            <TimelineAnimationName>
                <span>{animation.elementName ?? animation.name}</span>
                {animation.isCustom && !animation.waitForClick &&
                    <DurationControl
                        value={animation.duration / 1000}
                        min={1}
                        max={100}
                        onChange={handleDurationChange}
                        style={{ width: 43, marginLeft: 13 }}
                    />
                }
            </TimelineAnimationName>
            {animation.isCustom && <RemoveCustomAnimationIconButton onMouseDown={event => event.stopPropagation()} onClick={onRemoveCustomAnimation} />}
            {showBottomBar && <TimelineAnimationBottomBar />}
        </TimelineAnimationContainer >
    );
}
