import React, { Component } from "react";
import styled from "styled-components";
import { app } from "js/namespaces";
import Loadable from "js/react/components/Loadable";
import {
    UIPane,
    UIPaneContents,
    UIPaneHeader,
    Section, BlueButton,
} from "js/react/components/UiComponents";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import Api from "js/core/api";
import isOrganizationSsoEnabled from "common/utils/isOrganizationSsoEnabled";
import getLogger from "js/core/logger";

import "css/billing.scss";

const logger = getLogger();

const SSOSpan = styled.div`
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 20px;
`;

const SSOContainer = styled.div`
  padding: 10px 0;
`;

const SSOStatusText = styled.p`
  margin-bottom: 10px;
`;

class SingleSignOn extends Component {
    initialState = {
        isOrganizationFetching: true,
        //once access to sso feature is enabled this flag used to determine if sso is enabled
        isSsoEnabled: false,
        organization: {},
        isStrictSSOEnabled: false,
    };

    state = { ...this.initialState };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.organizationId !== this.props.organizationId) {
            this.setState(this.initialState, () => {
                this.loadData();
            });
        }
    }

    loadData() {
        const { organizationId } = this.props;
        Api.sso.get({ id: organizationId })
            .then(organization => this.setStateFromOrganization(organization))
            .catch(err => {
                logger.error(err, "[AdvancedSettingPane] loadData() failed");

                ShowErrorDialog({
                    error: "Error",
                    message: "Failed to load your organization. Please email support@beautiful.ai for assistance."
                });
            });
    }

    /**
     * Converts an organization model object to a plain state object
     */
    setStateFromOrganization(organization) {
        this.setState({
            organization,
            isOrganizationFetching: false,
            isSsoEnabled: isOrganizationSsoEnabled(organization),
            isStrictSSOEnabled: isOrganizationSsoEnabled(organization) && organization.sso.strict
        });
    }

    renderSSOStatus() {
        const { isSsoEnabled, isStrictSSOEnabled } = this.state;
        if (isSsoEnabled) {
            return `SSO is enabled and ${isStrictSSOEnabled ? `required` : `optional` } for your organization.`;
        }
        return "SSO is not yet enabled for your organization.";
    }

    render() {
        const {
            isOrganizationFetching,
            isSsoEnabled,
        } = this.state;

        const {
            handleSSOConfigClicked,
        } = this.props;

        return (
            <UIPane>
                <UIPaneHeader>Single Sign-On</UIPaneHeader>
                <UIPaneContents>
                    <Loadable isLoading={isOrganizationFetching}>
                        <Section title="Single Sign-On">
                            <SSOSpan>Allow team members to login using a third-party identity provider</SSOSpan>
                            <SSOContainer>
                                <SSOStatusText>{this.renderSSOStatus()}</SSOStatusText>
                                <BlueButton onClick={handleSSOConfigClicked}>
                                    {isSsoEnabled ? "Edit Configuration" : "Enable SSO"}
                                </BlueButton>
                            </SSOContainer>
                        </Section>
                    </Loadable>
                </UIPaneContents>
            </UIPane>
        );
    }
}

export default SingleSignOn;
