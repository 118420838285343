import React from "reactn";
import PropTypes from "prop-types";
import InfoToolTip from "js/react/components/InfoToolTip";

const TextInput = ({
    children,
    classNames = [],
    curValue,
    handleBlur,
    handleChange,
    id,
    label,
    placeholder,
    disabled,
    maxLength,
    tooltipTitle
}) => (
    <div className={`textInput ${[...classNames]}`}>
        {tooltipTitle ? (
            <label htmlFor={`${id}`}>
                <InfoToolTip
                    label={label}
                    title={tooltipTitle}
                />
            </label>
        ) : (
            <label htmlFor={`${id}`}>{label}</label>
        )}

        <input
            id={`${id}`}
            value={`${curValue}`}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
            maxLength={maxLength}
            placeholder={placeholder}
        />
        {children}
    </div>
);

TextInput.propTypes = {
    classNames: PropTypes.array,
    curValue: PropTypes.string,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
};

export default TextInput;
