import React, { Component } from "react";
import styled from "styled-components";

import {
    Close as CloseIcon,
    Mouse as MouseIcon,
    PlayArrow as PlayArrowIcon,
    Settings as SettingsIcon
} from "@material-ui/icons";

import { AnimationsArrangementType, AnimationsState } from "common/constants";
import { getStaticUrl } from "js/config";

import { AnimationSpeedControl } from "./AnimationSpeedControl";
import { AnimationArrangementControl } from "./AnimationArrangementControl";
import { AnimationCustomTimelineEditor } from "./AnimationCustomTimelineEditor/AnimationCustomTimelineEditor";
import { BoxButton } from "../BoxButton";

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
`;

const AnimationTypeButtonsContainer = styled.div`
    width: 100%;
    margin-top: 5px;

    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
`;

const CurrentAnimationTypeDescription = styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    color: #222;
`;

const ClickShieldContainer = styled.div`
    position: absolute;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    left: -5px;
    top: -5px;
    z-index: 99999;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-image: linear-gradient(
        45deg, 
        rgba(0,0,0,0.2) 25%, 
        transparent 25%, 
        transparent 50%, 
        rgba(0,0,0,0.2) 50%, 
        rgba(0,0,0,0.2) 75%, 
        transparent 75%, 
        transparent
    );
    background-size: 5px 5px;
`;

const ClickShieldMessageContainer = styled.div`
    width: 100%;
    margin: 5px;
    background: #ffffff;
    box-shadow: 0px 9px 37px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    padding: 15px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    >h1 {
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.5px;
        color: #222;
        text-align: center;
    }

    >p {
        font-weight: normal;
        font-size: 12px;
        line-height: 125%;        
        letter-spacing: 0.5px;
        color: #222222;
        text-align: center;
    }

    >div {
        margin-top: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;        
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #11a9e2;
        cursor: pointer;
        padding: 2px 8px;    
        border-radius: 2px;

        &:hover {
            background: rgba(0, 0, 0, 0.05);
        }
    }
`;

function ClickShield({ onEditAnimationsClick }) {
    return (
        <ClickShieldContainer>
            <ClickShieldMessageContainer>
                <img src={getStaticUrl("/images/ui/animation/construction.svg")} />
                <h1>We’ve updated the animations editor—making it more intuitive and easier to use.</h1>
                <p>This may affect some of your playback settings if you continue to edit them. Or you may choose to leave them as they are, and they will play as expected.</p>
                <div onClick={onEditAnimationsClick}>edit animations</div>
            </ClickShieldMessageContainer>
        </ClickShieldContainer>
    );
}

export class AnimationEditor extends Component {
    constructor({ canvas }) {
        super();

        this.state = {
            animations: [],
            generalAnimationsSettings: canvas.getGeneralAnimationsSettings()
        };

        this.dataModelListenersConfig = {
            "change": this.onSlideChange,
            "updateDataState": this.onSlideUpdataDataState,
        };
    }

    componentDidMount() {
        const { canvas } = this.props;
        canvas.dataModel.on(this.dataModelListenersConfig);
        this.fetchAnimations();
    }

    componentWillUnmount() {
        const { canvas } = this.props;
        canvas.dataModel.off(this.dataModelListenersConfig);
    }

    componentDidUpdate(prevProps) {
        const newCanvas = this.props.canvas;
        const oldCanvas = prevProps.canvas;
        if (newCanvas !== oldCanvas) {
            oldCanvas.dataModel.off(this.dataModelListenersConfig);
            newCanvas.dataModel.on(this.dataModelListenersConfig);
            this.fetchAnimations();
        }
    }

    setStateAsync(stateUpdates) {
        return new Promise(resolve => this.setState(stateUpdates, resolve));
    }

    onSlideChange = (slide, eventProps = {}) => {
        if (!eventProps.isCommit) {
            // Not commit means the data state will be updated, so we ignore this event
            return;
        }
        this.fetchAnimations();
    }

    onSlideUpdataDataState = (slide, eventProps = {}) => {
        this.fetchAnimations();
    }

    fetchAnimations = async () => {
        const { canvas } = this.props;

        // If canvas is generating then we have to wait for it because some elements
        // can't calculate their animations until they render (i.e. Chart)
        if (canvas.layouter.isGenerating) {
            await new Promise(resolve => canvas.layouter.runPostRender(resolve));
        }

        await this.setStateAsync({
            animations: canvas.getAnimations(),
            generalAnimationsSettings: canvas.getGeneralAnimationsSettings()
        });
    }

    handleEditOldCustomAnimationsClick = async () => {
        const { canvas } = this.props;
        const { animations } = this.state;

        // Reset animations state
        animations
            // Only element animations will be reset
            .filter(({ isCustom }) => !isCustom)
            .forEach(({ id }) =>
                canvas.updateAnimation(id,
                    {
                        // ↓ Obsolete values removal ↓
                        start: null,
                        duration: null,
                        // ↑ Obsolete values removal ↑
                        // Wait for click will be force-disabled because it's substituted
                        // by custom wait animations
                        waitForClick: false
                    }
                )
            );

        canvas.updateGeneralAnimationsSettings({ state: AnimationsState.CUSTOM });

        await canvas.saveCanvasModel();
    }

    setAnimationsState = async state => {
        const { canvas } = this.props;
        canvas.updateGeneralAnimationsSettings({ state });
        await canvas.saveCanvasModel();
    }

    updateGeneralAnimationsSettings = async ({ speedMultiplier, arrangementType }) => {
        const { canvas } = this.props;
        canvas.updateGeneralAnimationsSettings({ speedMultiplier, arrangementType });
        await canvas.saveCanvasModel();
    }

    render() {
        const {
            canvas,
            isAnimating,
            animatingAnimationIds
        } = this.props;
        const {
            animations,
            generalAnimationsSettings
        } = this.state;

        const currentAnimationsState = generalAnimationsSettings.state;

        return (
            <Container>
                {currentAnimationsState === AnimationsState.CUSTOM_OLD_TIMELINE &&
                    <ClickShield
                        onEditAnimationsClick={this.handleEditOldCustomAnimationsClick}
                    />
                }
                <AnimationTypeButtonsContainer>
                    <BoxButton
                        selected={currentAnimationsState === AnimationsState.NONE}
                        onClick={() => this.setAnimationsState(AnimationsState.NONE)}
                        disabled={isAnimating}
                        IconType={CloseIcon}
                        labelText={"none"}
                        width={"calc((100% - 15px) / 4)"}
                        containerStyle={{ marginRight: "5px" }}
                    />
                    <BoxButton
                        selected={currentAnimationsState === AnimationsState.ON_CLICK}
                        onClick={() => this.setAnimationsState(AnimationsState.ON_CLICK)}
                        disabled={isAnimating}
                        IconType={MouseIcon}
                        labelText={"on click"}
                        width={"calc((100% - 15px) / 4)"}
                        containerStyle={{ marginRight: "5px" }}
                    />
                    <BoxButton
                        selected={currentAnimationsState === AnimationsState.AUTO}
                        onClick={() => this.setAnimationsState(AnimationsState.AUTO)}
                        disabled={isAnimating}
                        IconType={PlayArrowIcon}
                        labelText={"auto"}
                        width={"calc((100% - 15px) / 4)"}
                        containerStyle={{ marginRight: "5px" }}
                    />
                    <BoxButton
                        selected={currentAnimationsState === AnimationsState.CUSTOM}
                        onClick={() => this.setAnimationsState(AnimationsState.CUSTOM)}
                        disabled={isAnimating}
                        IconType={SettingsIcon}
                        labelText={"custom"}
                        width={"calc((100% - 15px) / 4)"}
                    />
                </AnimationTypeButtonsContainer>
                {currentAnimationsState !== AnimationsState.CUSTOM && currentAnimationsState !== AnimationsState.CUSTOM_OLD_TIMELINE &&
                    <CurrentAnimationTypeDescription>
                        {currentAnimationsState === AnimationsState.NONE && "Items will not animate."}
                        {currentAnimationsState === AnimationsState.ON_CLICK && "Each item will animate in after a click."}
                        {currentAnimationsState === AnimationsState.AUTO && "Items will animate in automatically."}
                    </CurrentAnimationTypeDescription>
                }
                {(currentAnimationsState === AnimationsState.CUSTOM || currentAnimationsState === AnimationsState.CUSTOM_OLD_TIMELINE) &&
                    <AnimationCustomTimelineEditor
                        canvas={canvas}
                        animations={animations}
                        generalAnimationsSettings={generalAnimationsSettings}
                        isAnimating={isAnimating}
                        animatingAnimationIds={animatingAnimationIds}
                    />
                }
                {currentAnimationsState === AnimationsState.AUTO &&
                    <AnimationArrangementControl
                        isAnimating={isAnimating}
                        animationsArrangementType={generalAnimationsSettings.arrangementType}
                        setAnimationsArrangementType={arrangementType => this.updateGeneralAnimationsSettings({ arrangementType })}
                    />
                }
                {currentAnimationsState !== AnimationsState.NONE &&
                    <AnimationSpeedControl
                        isAnimating={isAnimating}
                        animationsSpeedMultiplier={generalAnimationsSettings.speedMultiplier}
                        setAnimationsSpeedMultiplier={speedMultiplier => this.updateGeneralAnimationsSettings({ speedMultiplier })}
                    />
                }
            </Container>
        );
    }
}
