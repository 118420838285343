import React, { Component } from "react";
import styled from "styled-components";
import { AuthoringBlockType, TextStyleType } from "common/constants";
import * as geom from "js/core/utilities/geom";
import { $ } from "js/vendor";

const Container = styled.div`
    width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const BlockContainer = styled.div`
  background: #ddd;
  padding: 6px;
  border: solid 1px black;
  color: #333;
  text-transform: uppercase;
`;

export class BlockLibrary extends Component {
    render() {
        let blockType = [
            TextStyleType.HEADLINE,
            TextStyleType.HEADING,
            TextStyleType.TITLE,
            TextStyleType.BODY,
            TextStyleType.BULLET_LIST,
            TextStyleType.CAPTION,
            TextStyleType.LABEL,
            AuthoringBlockType.MEDIA,
            AuthoringBlockType.DIVIDER,
            AuthoringBlockType.CODE,
            AuthoringBlockType.EQUATION,
        ];

        return (
            <Container>
                {blockType.map(type => <Block key={type} type={type}/>)}
            </Container>
        );
    }
}

class Block extends Component {
    handleDrag = event => {

        // element.canvas.selectionLayer.dragBlockManager.registerDrag(this, element, dragBlocks, rolloverBlock);
    }

    handleMouseDown = event => {
        const { onDrag, canReorder } = this.props;

        const startPt = new geom.Point(event.pageX, event.pageY);
        $(document).on("mousemove.blockmenuwidget", event => {
            if (new geom.Point(event.pageX, event.pageY).distance(startPt) > 5) {
                this.handleDrag(event);
                $(document).off(".blockmenuwidget");
            }
        });

        $(document).on("mouseup.blockmenuwidget", event => {
            $(document).off(".blockmenuwidget");
        });
    }

    render() {
        const { type } = this.props;

        return (
            <BlockContainer
                onMouseDown={this.handleMouseDown}
                onDrag={this.handleDrag}
            >{type}</BlockContainer>
        );
    }
}

