import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Icon } from "@material-ui/core";

import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import { Gap10 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";

const IconButtonContainer = styled.div.attrs(({ disabled }) => ({
    style: {
        opacity: disabled ? 0.3 : 1
    }
}))`
    margin: 0px 5px;
`;

const StyledIcon = styled(Icon)`
    &&& {
        font-size: 22px;
        opacity: 0.8;

        :hover {
            opacity: 1;
            color: #50bbe6;
        }
    }
`;

const UndoRedo = () => {
    const [stackPosition, setStackPosition] = useState(app.undoManager.stackPosition);
    const [undoStack, setUndoStack] = useState(app.undoManager.undoStack);

    useEffect(() => {
        app.undoManager.on("undo redo undoStackChanged", onStackChange);
        return () => {
            return app.undoManager.off("undo redo undoStackChanged", onStackChange);
        };
    }, []);

    const onStackChange = () => {
        setStackPosition(app.undoManager.stackPosition);
        setUndoStack(app.undoManager.undoStack);
    };

    const onUndoClick = () => {
        ds.selection.element = null;
        app.undoManager.undo();
    };

    const onRedoClick = () => {
        ds.selection.element = null;
        app.undoManager.redo();
    };

    return (
        <FlexBox>
            <IconButtonContainer
                onClick={onUndoClick}
                disabled={stackPosition === -1}
            >
                <StyledIcon>undo</StyledIcon>
            </IconButtonContainer>
            <Gap10 />
            <IconButtonContainer
                onClick={onRedoClick}
                disabled={stackPosition === undoStack.length - 1}
            >
                <StyledIcon>redo</StyledIcon>
            </IconButtonContainer>
        </FlexBox>
    );
};

export default UndoRedo;
