import React from "react";
import { useDrop } from "react-dnd";

import { NodeType } from "common/constants";
import { ds } from "js/core/models/dataService";
import { Convert } from "js/core/utilities/geom";
import { trackActivity } from "js/core/utilities/utilities";
import { _ } from "js/vendor";

export const AddElementDropZone = ({ children, canvasBounds, isDragging, currentCanvas, onClose }) => {
    let container;

    if (currentCanvas.dataModel.get("template_id") === "flow_chart") {
        // special case for flow chart because we need to drop the annotations into the flow chart primary element
        container = currentCanvas.getPrimaryElement();
    } else {
        container = currentCanvas.layouter.elements.annotations;
    }

    const [, drop] = useDrop({
        accept: "elements",
        drop: ({ value }, monitor) => {
            const didDrop = monitor.didDrop();
            if (didDrop) {
                return;
            }

            const clientOffset = monitor.getClientOffset();

            const pt = Convert.ScreenToElementCoordinates(currentCanvas, container, clientOffset.x, clientOffset.y);

            if (pt.x < 0 || pt.x > container.bounds.width || pt.y < 0 || pt.y > container.bounds.height) {
                onClose();
                return;
            }

            const x = pt.x / container.bounds.width;
            const y = pt.y / container.bounds.height;

            const props = {
                "slide_id": currentCanvas.dataModel.id,
                "template_id": currentCanvas.slideTemplate.constructor.id,
                "template_title": currentCanvas.slideTemplate.constructor.title,
                "element_type": value
            };
            trackActivity("Slide", "ElementAdded", null, null, props);

            if (value === NodeType.LINE) {
                const connector = container.connectors.addItem({
                    sourcePoint: pt,
                    targetPoint: pt.offset(200, 0),
                    connectorType: "straight"
                });
                currentCanvas.updateCanvasModel().then(() => {
                    ds.selection.element = container.connectors.getItemElementById(connector.id);
                });
            } else {
                const annotation = container.addItem({
                    nodeType: value, x, y
                });

                currentCanvas.updateCanvasModel().then(() => {
                    ds.selection.element = container.getItemElementById(annotation.id);
                });
            }

            onClose();
        },

        collect: monitor => {
            return {
                isOver: monitor.isOver(),
            };
        },
    });

    return (
        <div id="drop-zone" style={{
            position: "absolute",
            // To prevent the drop zone from being covered by the canvas
            zIndex: isDragging ? 100 : 0,
            width: canvasBounds.width,
            height: canvasBounds.height,
            top: canvasBounds.top,
            left: canvasBounds.left,
        }} ref={drop}>
            {children}
        </div>
    );
};
