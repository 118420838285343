import React, { Component } from "react";

import Api from "js/core/api";
import getLogger from "js/core/logger";
import * as salesforceAuth from "js/core/oauth/salesforceAuth";
import Loadable from "js/react/components/Loadable";
import { Gap10 } from "js/react/components/Gap";
import { Section, BlueButton, GreenButton, ScaryButton } from "js/react/components/UiComponents";
import TextInput from "../components/TextInput";
import { ShowErrorDialog, ShowMessageDialog } from "js/react/components/Dialogs/BaseDialog";

const logger = getLogger();

export default class SalesforceIntegration extends Component {
    state = {
        clientId: "",
        domainName: "",
        prn: "",
        orgHasSalesforceLinked: false,
        orgSalesforceLinkFetching: false,
    };

    async componentDidMount() {
        const { hasTokens } = await Api.getSalesforceTokens.get({ orgId: this.props.organizationId });

        this.setState({ orgHasSalesforceLinked: hasTokens });
    }

    handleClientIdChange = event => {
        this.setState({ clientId: event.target.value });
    }

    handleDomainNameChange = event => {
        this.setState({ domainName: event.target.value });
    }

    handlePrnChange = event => {
        this.setState({ prn: event.target.value });
    }

    handleConnectSalesforce = async () => {
        try {
            const { organizationId } = this.props;
            const { clientId, domainName, prn } = this.state;

            const tokens = await salesforceAuth.grantOfflineAccess(organizationId, clientId, domainName, prn);

            logger.info("Salesforce auth flow completed, saving tokens", { tokens });
            this.setState({ orgHasSalesforceLinked: true });
        } catch (err) {
            logger.error(err, "handleConnectSalesforce() failed");
            ShowErrorDialog({ title: "Failed to connect Salesforce", message: err.message });
        }
    }

    handleDisconnectSalesforce = async () => {
        try {
            await Api.unlinkSalesforceAuth.delete({ orgId: this.props.organizationId });

            logger.info("Salesforce tokens deleted");
            this.setState({ orgHasSalesforceLinked: false });
        } catch (err) {
            logger.error(err, "handleDisconnectSalesforce() failed");
            ShowErrorDialog({ title: "Failed to disconnect Salesforce", message: err.message });
        }
    }

    render() {
        const {
            clientId, domainName, prn, orgHasSalesforceLinked, orgSalesforceLinkFetching
        } = this.state;

        return (
            <Loadable isLoading={orgSalesforceLinkFetching}>
                <Section title="Salesforce Integration">
                    {!orgHasSalesforceLinked && <>
                        <div className="privacy-notice-text">
                            Connect your Salesforce account to track links and views of presentations sent to your Salesforce contacts.
                        </div>
                        <TextInput
                            id="salesforce-client-id"
                            label="Salesforce Client ID:"
                            curValue={clientId}
                            handleChange={event => this.handleClientIdChange(event)}
                        />
                        <Gap10 />
                        <TextInput
                            id="salesforce-domain-name"
                            label="Salesforce Domain Name:"
                            curValue={domainName}
                            handleChange={event => this.handleDomainNameChange(event)}
                        />
                        <Gap10 />
                        <TextInput
                            id="salesforce-prn"
                            label="Salesforce Integration Username:"
                            curValue={prn}
                            handleChange={event => this.handlePrnChange(event)}
                        />
                        <Gap10 />
                        <BlueButton
                            disabled={!clientId}
                            onClick={this.handleConnectSalesforce}
                        >
                            Connect Salesforce
                        </BlueButton>
                    </>}
                    {orgHasSalesforceLinked && <>
                        <div className="privacy-notice-text">
                            Salesforce account is connected.
                        </div>
                        <ScaryButton onClick={this.handleDisconnectSalesforce}>
                            Disconnect Salesforce
                        </ScaryButton>
                    </>}
                </Section>
            </Loadable>
        );
    }
}
