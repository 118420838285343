import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import { IconButton } from "@material-ui/core";
import {
    PlayCircleFilled as PlayCircleFilledIcon,
    Stop as StopIcon,
    Close as CloseIcon
} from "@material-ui/icons";

import getLogger, { LogGroup } from "js/core/logger";
import { BlueButton } from "js/react/components/UiComponents";
import * as geom from "js/core/utilities/geom";
import { Key } from "js/core/utilities/keys";
import { unlockAudio, prepareToUnlockAudio } from "js/core/utilities/audioUtilities";

import { FlexSpacer } from "js/react/components/Gap";
import { AnimationEditor } from "./Components/AnimationEditor/AnimationEditor";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

const logger = getLogger(LogGroup.ANIMATION);

export const AnimationPanelTabType = {
    ANIMATION: "animation",
    AUDIO: "audio"
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background: #f1f1f1;
`;

const Header = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  padding-right: 10px;

  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #666666;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  padding: 10px;
`;

const PreviewButtonContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

const PreviewButton = styled(BlueButton)`
  &&& {
    height: 40px;
    pointer-events: auto;
    border-radius: 0;
  }
`;

function AnimationPanel({
    tab,
    isReadOnly,
    onClose,
    currentCanvasController
}) {
    const canvas = currentCanvasController.canvas;

    const [isPreviewDisabled, setPreviewDisabled] = useState(true);
    const [isAnimating, setAnimating] = useState(canvas.isAnimating);
    const [animatingAnimationIds, setAnimatingAnimationIds] = useState([]);
    const previewButtonContainerRef = useRef();

    useEffect(() => {
        prepareToUnlockAudio()
            .then(() => setPreviewDisabled(false))
            .catch(err => logger.error(err, "[AnimationPanel] prepareToUnlockAudio() failed"));
    }, [0]);

    const animate = async () => {
        if (isAnimating) {
            canvas.stopAnimation();
            return;
        }

        unlockAudio();

        const previewButtonBounds = geom.Rect.FromBoundingClientRect(previewButtonContainerRef.current.getBoundingClientRect()).deflate(10);

        const removeClickShield = canvas.showClickShield(
            event => {
                if (previewButtonBounds.contains(event.pageX, event.pageY)) {
                    canvas.stopAnimation();
                }
            },
            event => {
                if (event.which == Key.ESCAPE) {
                    canvas.stopAnimation();
                }
            },
            "wait"
        );

        setAnimating(true);
        canvas.animate(
            (progress, animationHasFutureWaitForClick, animatingAnimationIds) => setAnimatingAnimationIds(animatingAnimationIds),
            false
        ).then(() => {
            setAnimatingAnimationIds([]);
            setAnimating(false);

            removeClickShield();
        });
    };

    return (
        <Container>
            <Header>
                Animation
                <FlexSpacer/>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </Header>
            <PreviewButtonContainer ref={previewButtonContainerRef}>
                <PreviewButton
                    onClick={animate}
                    startIcon={isAnimating ? <StopIcon/> : <PlayCircleFilledIcon/>}
                    disabled={isPreviewDisabled}
                    fullWidth
                    disableElevation
                >
                    {isAnimating ? "Press ESC to stop" : "Preview"}
                </PreviewButton>
            </PreviewButtonContainer>
            <ContentContainer>
                <AnimationEditor
                    canvas={canvas}
                    isReadOnly={isReadOnly}
                    animate={animate}
                    isAnimating={isAnimating}
                    animatingAnimationIds={animatingAnimationIds}
                />
            </ContentContainer>
        </Container>
    );
}

export default PresentationEditorController.withState(AnimationPanel);
