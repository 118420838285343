import React, { Component, Fragment } from "react";
import moment from "moment";
import styled from "styled-components";
import { Button } from "@material-ui/core";

import { slides as slidesApi } from "apis/callables";
import { _ } from "js/vendor";
import { UndoType } from "common/constants";
import { FlexSpacer } from "js/react/components/Gap";
import { app } from "js/namespaces";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";

import { getCanvasBundle } from "js/canvas";
import { ds } from "js/core/models/dataService";
import { appVersion } from "js/config";
import Spinner from "js/react/components/Spinner";
import { RevisionGroup } from "./RevisionGroup";

const RevisionsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  overflow: auto;

  .loadBtn {
    width: calc(100% - 20px);
    height: 40px;
    margin: 10px;
  }
  
  hr {
    background: rgb(241, 241, 241);
    margin: 0px;
    height: 1px;
    border: none;
  }
`;

const WarningMessage = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;

const TimedSegmentLabel = styled.div`
  background: #f1f1f1;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  padding: 8px 12px;
`;

export class RevisionPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            revisions: [],
            page: 1,
            isEnd: false,
            isLoading: true,
            isSaving: false,
            currentRevision: null
        };

        this.previewRevisionPromiseChain = Promise.resolve();
    }

    setStateAsync = state => new Promise(resolve => this.setState(state, resolve));

    componentDidMount() {
        const { currentSlide, currentCanvasController } = this.props;

        currentSlide.detachAdapter();
        currentCanvasController.lockSlideForCollaborators(60 * 60);
        currentCanvasController.freezeCollaboratorsLock = true;

        this.fetchRevisions();
    }

    async componentWillUnmount() {
        const { currentSlide, currentCanvasController } = this.props;

        currentCanvasController.freezeCollaboratorsLock = false;
        currentCanvasController.unlockSlideForCollaborators();

        if (!currentSlide.isDetached) {
            // All good, already saved
            return;
        }

        currentSlide.update(currentSlide.originalState, { removeMissingKeys: true, silent: true, save: false });
        await currentSlide.saveChanges();
        await currentCanvasController.reloadCanvas();
    }

    async fetchRevisions() {
        const { currentSlide } = this.props;
        const { page } = this.state;

        const loadedRevisions = [];

        const { revisions: slideRevisions } = await slidesApi.getSlideRevisions({ id: currentSlide.id, page: page, pageSize: 50 });
        if (slideRevisions.length > 1) {
            for (const slideRevision of slideRevisions) {
                const index = slideRevisions.indexOf(slideRevision);
                const { revisionTimestamp, revisionCreatedBy } = slideRevision;

                if (slideRevision.revisionIsInitialState) {
                    this.setState({ isEnd: true });
                }

                const currentTemplate = slideRevision.template_id;
                const nextRevision = slideRevisions[index + 1];
                const nextTemplate = nextRevision?.template_id;
                const convertedTo = nextTemplate && nextTemplate !== currentTemplate ? currentTemplate : null;

                const state = {
                    ...currentSlide.attributes,
                    ..._.omit(slideRevision, ["slideId", "revisionTimestamp", "revisionCreatedBy", "revisionIsInitialState", "createdAt", "modifiedAt"]),
                    id: currentSlide.id
                };

                const revisionItemState = {
                    userId: revisionCreatedBy,
                    timestamp: revisionTimestamp,
                    state,
                    time: moment(revisionTimestamp).format("LTS"),
                    isLastRevision: index === 0 && page == 1
                };

                if (slideRevision.revisionIsInitialState) {
                    revisionItemState.hint = "Initial Creation";
                } else if (convertedTo) {
                    const { slideTemplates: { slideTemplates } } = await getCanvasBundle(nextRevision.version ?? appVersion);
                    revisionItemState.hint = `Converted to ${slideTemplates[convertedTo].title}`;
                } else if (nextRevision && slideRevision.version !== nextRevision.version && slideRevision.version >= 10) {
                    revisionItemState.hint = `Updated Version to ${slideRevision.version}`;
                }

                loadedRevisions.push(revisionItemState);
            }

            this.setState(({ ...state }) => ({
                ...state,
                isLoading: false,
                revisions: [...state.revisions, ...loadedRevisions],
                currentRevision: state.currentRevision ?? loadedRevisions[0]
            }));
        } else {
            this.setState({
                isEnd: true,
                isLoading: false,
                revisions: [],
                currentRevision: null
            });
        }
    }

    loadNextPage = () => {
        this.setState({ page: this.state.page + 1 }, () => {
            this.fetchRevisions();
        });
    }

    handlePreviewRevision = revision => {
        const { isSaving } = this.state;
        if (isSaving) {
            return;
        }

        return new Promise((resolve, reject) => {
            this.previewRevisionPromiseChain = this.previewRevisionPromiseChain
                .then(async () => {
                    const { currentCanvasController, currentSlide } = this.props;
                    const { isSaving } = this.state;

                    if (isSaving) {
                        return;
                    }

                    currentSlide.update(revision.state, { removeMissingKeys: true, silent: true, save: false });
                    await this.setStateAsync({ currentRevision: revision });

                    await currentCanvasController.reloadCanvas();
                })
                .then(resolve)
                .catch(reject);
        });
    }

    showConfirmationDialog = revision => ShowConfirmationDialog({
        title: `Are you sure you want to restore this slide to the version created on ${moment(revision.timestamp).format("ddd, MMM Do YYYY")} at ${revision.time}?`
    });

    saveRevision = async revision => {
        const { currentSlide } = this.props;

        currentSlide.update(revision.state, { removeMissingKeys: true, silent: true, save: false });

        // saveChanges to reattached adapter and commit
        const { hasUpdates } = await currentSlide.saveChanges();

        if (hasUpdates && app.undoManager) {
            app.undoManager.set(
                UndoType.SLIDE_DATA, currentSlide.id,
                { attributes: currentSlide.originalState }, { attributes: revision.state }
            );
        }
    }

    handleSaveRevision = async revision => {
        const { onClose } = this.props;

        await this.setStateAsync({ isSaving: true });

        const isConfirmed = await this.showConfirmationDialog(revision);
        if (!isConfirmed) {
            await this.setStateAsync({ isSaving: false });
            await this.renderCurrentRevision();
            return;
        }

        await this.saveRevision(revision);

        onClose();
    }

    handleCopyRevision = async revision => {
        await this.setStateAsync({ isSaving: true });

        const { onClose, currentSlide } = this.props;

        const insertIndex = ds.selection.presentation.getSlideIndex(currentSlide.id) + 1;
        await ds.selection.presentation.duplicateSlide(revision.state, { insertIndex, isSlideCreatedFromUserAction: true });

        await this.setStateAsync({ isSaving: false });

        await this.renderCurrentRevision();

        onClose();
    }

    renderCurrentRevision = () => {
        const { revisions: [currentSlideRevision], currentRevision } = this.state;
        if (!currentSlideRevision || currentSlideRevision == currentRevision) {
            return;
        }
        return this.handlePreviewRevision(currentSlideRevision);
    }

    render() {
        const { currentSlide } = this.props;
        const { revisions, isEnd, isLoading, currentRevision } = this.state;

        if (isLoading) {
            return <Spinner />;
        }

        if (revisions.length == 0) {
            return <WarningMessage>There are no previous versions of this slide</WarningMessage>;
        }

        const timedRevisions = _.groupBy(revisions, revision => {
            if (moment().diff(moment(revision.timestamp), "hours") < 1) {
                return "Recent versions";
            } else {
                return moment(revision.timestamp).fromNow();
            }
        });

        const groupedRevisions = {};
        for (const group of Object.keys(timedRevisions)) {
            const revisions = timedRevisions[group];

            let parentRevision = revisions[0];
            groupedRevisions[group] = [[parentRevision]];

            for (let i = 1; i < revisions.length; i++) {
                const revision = revisions[i];
                if (revision.hint) {
                    groupedRevisions[group].push([revision]);
                    parentRevision = null;
                } else {
                    if (parentRevision && parentRevision.timestamp - revision.timestamp < 1000 * 60) {
                        _.last(groupedRevisions[group]).push(revision);
                    } else {
                        groupedRevisions[group].push([revision]);
                        parentRevision = revision;
                    }
                }
            }
        }

        const Revisions = [];
        let revisionsRenderedSoFar = 0;

        for (const [timeLabel, revisionTimeSegment] of Object.entries(groupedRevisions)) {
            const RevisionGroups = [];
            for (const [i, revisionGroup] of Object.entries(revisionTimeSegment)) {
                RevisionGroups.push(
                    <RevisionGroup key={"RevisionGroup" + i}
                        expanded={revisionsRenderedSoFar < 5}
                        currentSlide={currentSlide}
                        revisionGroup={revisionGroup}
                        onPreviewRevision={this.handlePreviewRevision}
                        onSaveRevision={this.handleSaveRevision}
                        onCopyRevision={this.handleCopyRevision}
                        currentRevision={currentRevision}
                    />
                );
                revisionsRenderedSoFar += revisionGroup.length;
            }

            Revisions.push(
                <Fragment key={timeLabel}>
                    <TimedSegmentLabel key={timeLabel}>{timeLabel}</TimedSegmentLabel>
                    {RevisionGroups}
                </Fragment>
            );
        }

        return (
            <RevisionsContainer>
                {Revisions}
                <FlexSpacer />
                {!isEnd &&
                    <Button className="loadBtn" onClick={this.loadNextPage}>Load more</Button>
                }
            </RevisionsContainer>
        );
    }
}

