import React, { Component, Fragment } from "react";
import { _ } from "js/vendor";
import { Button, Icon, IconButton, Select, MenuItem } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import UploadLogo from "js/editor/ThemeEditor/components/Footer/UploadLogo";
import { FlexBox } from "js/react/components/LayoutGrid";
import styled from "styled-components";
import { FlexSpacer, Gap10, Gap20 } from "js/react/components/Gap";
import { SimpleLabel } from "js/react/components/SimpleLabel";

export class ThemeBackgroundImages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundImages: _.cloneDeep(props.theme.backgroundImages) || []
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme.backgroundImages != this.props.theme.backgroundImages) {
            this.setState({ backgroundImages: _.cloneDeep(this.props.theme.backgroundImages) });
        }
    }

    handleAddBG = () => {
        let backgroundImages = this.state.backgroundImages || [];

        backgroundImages.push({
            id: uuid(),

        });

        this.setState({ backgroundImages });
    }

    handleUpdateImage = (props, backgroundId) => {
        let background = this.state.backgroundImages.find(bg => bg.id == backgroundId);
        if (background) {
            background = Object.assign(background, props);

            this.props.update({ backgroundImages: _.cloneDeep(this.state.backgroundImages) });
            this.setState({ backgroundImages: this.state.backgroundImages });
        }
    }

    handleDeleteImage = backgroundId => {
        let background = this.state.backgroundImages.find(bg => bg.id == backgroundId);
        if (background) {
            this.state.backgroundImages.remove(background);

            let props = { backgroundImages: _.cloneDeep(this.state.backgroundImages) };
            if (backgroundId == this.props.theme.defaultBackgroundColor) {
                props.defaultBackgroundColor = "background_light";
            }
            this.props.update(props);

            this.setState({ backgroundImages: this.state.backgroundImages });
        }
    }

    render() {
        let { backgroundImages } = this.state;

        return (
            <FlexBox vertical left gap={10}>
                {backgroundImages.map(bg => (
                    <BackgroundImage
                        key={bg.id}
                        backgroundImage={bg}
                        onUpdate={props => this.handleUpdateImage(props, bg.id)}
                        onDelete={() => this.handleDeleteImage(bg.id)}
                    />
                ))}
                <Button size="small" onClick={this.handleAddBG}><Icon color="primary" style={{ marginRight: -5 }}>add_circle_outline</Icon>Add</Button>
            </FlexBox>

        );
    }
}

const BGImageContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;

  .MuiIconButton-root {
    opacity: 0;
  }

  &:hover {
    background: #f5f5f5;
    outline: solid 5px #f5f5f5;
    
    .MuiIconButton-root {
      opacity: 1;
    }
  }

`;

class BackgroundImage extends Component {
    render() {
        let { backgroundImage, onUpdate, onDelete } = this.props;

        return (
            <BGImageContainer>
                <UploadLogo
                    filePickerOnClick={!backgroundImage.asset}
                    src={backgroundImage.asset}
                    update={onUpdate}
                    attribute="asset"
                    disableImageProcessing
                />
                <Gap10 />
                <FlexBox left middle vertical>
                    <SimpleLabel>Treat as</SimpleLabel>
                    <Select size="small" variant="outlined"
                        value={backgroundImage.colorStyle || "light"}
                        onChange={event => onUpdate({ colorStyle: event.target.value })}
                    >
                        <MenuItem value={"light"}>Light</MenuItem>
                        <MenuItem value={"dark"}>Dark</MenuItem>
                        <MenuItem value={"color"}>Color</MenuItem>
                    </Select>
                </FlexBox>
                <Gap20 />
                <FlexSpacer/>
                <IconButton onClick={onDelete}><Icon>close</Icon></IconButton>
                <Gap10/>
            </BGImageContainer>
        );
    }
}
