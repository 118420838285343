import React, { Component } from "react";
import styled from "styled-components";

import { AdvanceSlideOnType } from "common/constants";

import {
    Mouse as MouseIcon,
    MusicNote as MusicNoteIcon, VideoCall,
} from "@material-ui/icons";

import { BoxButton } from "js/react/views/AnimationPanel/Components/BoxButton";
import { DurationControl } from "js/react/views/AnimationPanel/Components/DurationControl";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

const Container = styled.div`
    padding: 20px;

    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Header = styled.span`
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #666666;
    margin-bottom: 20px;
`;

const AdvanceTypeSelectContainer = styled.div`
    //width: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

class AdvanceNextSlidePopup extends Component {
    handleChangeType = advanceOn => {
        const { currentSlide } = this.props;
        currentSlide.update({ advanceOn });
        // this.forceUpdate();
    }

    handleChangeDelay = advanceDelay => {
        const { currentSlide } = this.props;
        currentSlide.update({ advanceDelay });
        // this.forceUpdate();
    }

    render() {
        const { currentSlide } = this.props;

        const hasAudioAsset = currentSlide.has("audioAsset");
        const hasVideoOverlay = currentSlide?.dataState?.elements?.annotations?.videoOverlay?.videoAssetId != null;

        let advanceOn = currentSlide.get("advanceOn") ?? AdvanceSlideOnType.CLICK;
        if (advanceOn === AdvanceSlideOnType.AUDIO && !hasAudioAsset) {
            advanceOn = AdvanceSlideOnType.CLICK;
        }
        let advanceDelay = currentSlide.get("advanceDelay") ?? 5;

        return (
            <Container>
                <Header>Play next slide</Header>

                <AdvanceTypeSelectContainer>
                    <BoxButton
                        selected={advanceOn === AdvanceSlideOnType.CLICK}
                        onClick={() => this.handleChangeType(AdvanceSlideOnType.CLICK)}
                        IconType={MouseIcon}
                        backgroundColor="#eeeeee"
                        selectedBackgroundColor="#dbe9f0"
                        labelText={"on click"}
                        width={80}
                        height={"58px"}
                        containerStyle={{ marginRight: "5px" }}
                    />
                    <BoxButton
                        selected={advanceOn === AdvanceSlideOnType.DELAY}
                        onClick={() => this.handleChangeType(AdvanceSlideOnType.DELAY)}
                        CustomControl={<DurationControl value={advanceDelay}
                            onChange={this.handleChangeDelay} />}
                        backgroundColor="#eeeeee"
                        selectedBackgroundColor="#dbe9f0"
                        labelText={"timed"}
                        width={80}
                        height={"58px"}
                        containerStyle={{ marginRight: "5px" }}
                    />
                    {hasAudioAsset &&
                        <BoxButton
                            selected={advanceOn === AdvanceSlideOnType.AUDIO}
                            onClick={() => this.handleChangeType(AdvanceSlideOnType.AUDIO)}
                            IconType={MusicNoteIcon}
                            backgroundColor="#eeeeee"
                            selectedBackgroundColor="#dbe9f0"
                            labelText={"after audio"}
                            width={80}
                            height={"58px"}
                        />
                    }
                    {hasVideoOverlay &&
                        <BoxButton
                            selected={advanceOn === AdvanceSlideOnType.VIDEO}
                            onClick={() => this.handleChangeType(AdvanceSlideOnType.VIDEO)}
                            IconType={VideoCall}
                            backgroundColor="#eeeeee"
                            selectedBackgroundColor="#dbe9f0"
                            labelText={"after video"}
                            width={80}
                            height={"58px"}
                        />
                    }
                </AdvanceTypeSelectContainer>
            </Container>
        );
    }
}

export default PresentationEditorController.withState(AdvanceNextSlidePopup);
